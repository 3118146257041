import Vue from 'vue'
import App from './App.vue'
import router from './router'
import AOS from 'aos'
import 'aos/dist/aos.css'
window.axios = require('axios');

import store from './store'

Vue.config.productionTip = false


// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.getElementsByTagName('meta');

    Object.keys(tagDef).forEach(key => {
      document.getElementsByTagName('meta')[key].content = tagDef[key];
      //document.getElementsByTagName('meta')[key].content = tagDef[key]
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag['data-vue-router-controlled'] = '';

  })
  // Add the meta tags to the document head.

  next();
});

new Vue({
  created() {
    AOS.init({ disable: 'phone', offset: 200, duration: 600, startEvent: 'DOMContentLoaded' })
  },
  store,
  router,
  render: h => h(App),
}).$mount('#app')

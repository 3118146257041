import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        signed: false,
        userGoogleData: {},
        theme: 'khaki_black_theme',
        lang: localStorage.getItem('lang') || 'eng',
        content: {
            eng: {
                header: ['Guide', 'Trade Up Simulator', 'News', 'About', 'Free Skins'],
                about_us: ['About', 'About Us',
                    'We are group of Web Developers and CS:GO fans. App made with Vue.js. This app was build to help you deal with CS:GO Trade Ups. If you have any questions, feel free to contact us.',
                    'About Program',
                    "Our software generate nearly 37.000 trade ups. Prices are taken from steam monthly average sales. There are links to Bitskins and Steam on every single skin. In 'float' tab you can see lowest float which you can get if you will put 10 skins with lowest possible float. In 'Max Input Float' tab you can see the highest input float for every single output skin. You can also see the chance of drop in 'Chance' tab. And finally 'Average profit' tab will show you of course the average profit of whole trade up. You can also press right arrow button, which will switch trade up to random one."],
                guide: ['How to make a Profit from CS:GO Trade Ups', 'What is Trade Up Contract?', '“In exchange for 10 items of identical quality, you will receive a single item of a quality one higher, from a collection of one of the items you provided.”', '-Valve',
                    'Simply, CS:GO Trade Up Contract allows you to exchange 10 items with same exterior into one item with higher exterior.',
                    'How Trade Up Contracts Works?',
                    'First of all, you need to understand, what exactly ',
                    'float value', ' is. So basically, ', 'float value',
                    ' is the precise wear value of a skin. This measure ranges from zero to one, where 0 is the least float. It also devided by ranges, on the basis of which the ',
                    'quality', ' of the skin is determined.',
                    'Nowadays Trade Ups are based on ', 'Outcome',
                    '. It means that if your input contains ', '5', ' similar skins with ', '5',
                    ' possible outcomes and another ', '5', ' similar skins with ', '1',
                    ' possible outcome, your chances: ', '16,666%', ' on each outcome (because we have ', '6', ' outputs => ', '1 / 6 = 16,666%', ').',
                    'Float can change output. The exact formula:',
                    'OutputFloat = (MaxFloat - MinFloat) * AVG + MinFloat.',
                    'AVG = Average float of your input skins.',
                    'MinFloat = MinFloat of possible reward.', 'MaxFloat = MaxFloat of possible reward.',
                    'What do you need to make a Trade Up?', '1) Make sure you have Prime Version of CS:GO.',
                    '2) You need 10 skins with the same quality. If you have no skins you can check out ',
                    'Free Skins ', 'tab, which contain many referal codes to most popular CS:GO sites ',
                    "You can't use both StatTrak™ and non StatTrak™ skins in one Trade Up Contract.",
                    "You can't also use: Souvenires, Knives, Gloves or Skin with highest quality grade in this collection.",
                    "3) Choose 10 skins with the same quality and click 'PROCEED...' button.",
                    '4) Enjoy your output.'],
                news: {
                    twelve_ten: {
                        header: "CS:GO Update from 10/18/2019: CS20 Case, CS20 Sticker Capsule",
                        body: ["In Game items:",
                            "- CS:GO added new 'CS20 Case' skin case and 'CS20 Sticker Capsule' sticker capsule.",
                            "Maps:", "- Updated Cache is now officially available in Normal, Battle to Death, Scramble modes.",
                            "Other:", "- Added -lowmemworkshop Game Launcher option to update very large map files in the Steam Workshop;",
                            "- Added a fov_tv_debug command that allows you to change the look of the GOTV roaming mode or demo playback;",
                            "- Optimized the performance of portal areas."]
                    },
                    twenty_seven_ten: {
                        header: "What's wrong with CS20 Case price?",
                        body: ["CS20 vs Prisma:", "First day: CS20 - 10$, Prisma - 22.81$", "Second day: CS20 - 7.51$, Prisma - 9.81$",
                            "Sixs day: CS20 - 0.64$, Prisma - 4.8$", "Seventh day: CS20 - 1.77$, Prisma - 4.91$",
                            "So why the price of CS20 case was quite lower than Prisma?", "The answer is: We had 100% chance of CS20 Case drop for six days and no one needed such a large number of cases.",
                            "As we can see on seventh day price was going higher, because 100% chance of CS20 drop was removed."]
                    }
                }
            },
            ukr: {
                header: ['Гайд', 'Симулятор Контрактів', 'Новини', 'Про Нас', 'Халявні Скіни'],
                about_us: ['Про', 'Про Нас',
                    'Ми - група веб-розробників та шанувальників CS:GO. Додаток, створений за допомогою Vue.js. Цей додаток створено, щоб допомогти вам краще розібратися з CS:GO Контрактами. Якщо у вас виникли запитання, не соромтеся зв’язатися з нами.',
                    'Про програму',
                    "Наше програмне забезпечення генерує майже 37 000 контрактів. Ціни беруться із середньомісячних продажів в стімі. На кожному скіні є посилання на Bitskins та Steam. На вкладці 'float' ви бачите найнижчий флоат, який ви можете отримати, якщо ви запхаєте 10 скінів з найменш можливим флоатом. На вкладці 'Max Input Float' ви можете побачити найвищий вхідний флоат для кожної вихідної ділянки. Ви також можете побачити шанс падіння на вкладці 'Chance'. І нарешті вкладка 'Average profit' покаже вам, звісно, середній профіт від контракту загалом. Ви також можете натиснути кнопку стрілки праворуч, яка переключить контракт на інший."],
                guide: ["Як отримати прибуток від CS:GO контрактів", "Що таке контракт?",
                    '" В обмін на 10 предметів однакової якості ви отримаєте один товар більш високої якості з колекції один із наданих вами предметів. "', " -Valve ", "По-простому CS:GO Контракт дозволяє обміняти 10 предметів з однаковою зовнішністю на один предмет з більш високою якістю.",
                    "Як працюють торгові контракти?", "Перш за все, ви повинні зрозуміти, що таке ", 'флоат. ', ' Отже, ', ' флоат ', '- точна зносошеність скіна. Цей показник коливається від нуля до одиниці, де 0 - найменш можливий флоат. Він також розділений діапазонами, на основі яких ',
                    " якість ", " скіна визначається.",
                    "Сьогодні контракти базуються на", " вихідних скінах",
                    '. Це означає, що якщо ваш вхід містить', ' 5 ', 'однакових скінів з ', ' 5 ',
                    "можливими вихідними скінами та інші", "5", "однакових скінів з", " 1 ",
                    'можливий вихідним скіном, ваші шанси:', '16, 666% ', ' на кожен результат (тому що у нас ', ' 6 ', ' вихідних скінів => ', ' 1/6 = 16,666% ', '). ',
                    'Float може змінити вихід. Точна формула: ',
                    'OutputFloat = (MaxFloat - MinFloat) * AVG + MinFloat.',
                    'AVG = середній флоат ваших вхідних скінів.',
                    'MinFloat = мінімальний флоат можливої ​​винагороди.', 'MaxFloat = максимальний флоат можливої ​​винагороди.',
                    "Що вам потрібно, щоб здійснити торгівлю? ", " 1) Переконайтеся, що у вас є Prime Version CS:GO. ",
                    '2) Вам потрібно 10 скінів з однаковою якістю. Якщо у вас немає скінів, то можете відвідати вкладку',
                    'Халявні Скіни', ', в якій знаходяться багато рефелальних кодів до найбільш популярних КС:ГО площадок. ',
                    "Ви не можете використовувати StatTrak™ та не StatTrak™ скіни, в одному контракті.",
                    "Ви також не можете використовувати сувеніри, ножі, рукавиці або скіни найвищої якості.",
                    "3) Виберіть 10 скінів з однаковою якістю та натисніть кнопку 'PROCEED ...'.",
                    "4) Насолоджуйтесь результатом."],
                news: {
                    twelve_ten: {
                        header: "Оновлення CS:GO від 18.10.2019: CS20 Case, CS20 Sticker Capsule",
                        body: ["Ігрові предмети:",
                            "- В CS:GO добавили новий кейс зі скінами 'CS20 Case' і капсулу зі стікерами 'CS20 Sticker Capsule'.",
                            "Карти:", "- Оновлений Cache тепер офіційно доступний в режимах 'Звичайний', 'Бій на смерть', 'Сутичка'.",
                            "Інше:", "- Доданий параметр запуску гри -lowmemworkshop, який дозволяє оновлювати дуже великі файли карт в Майстерні Steam;",
                            "- Додана команда fov_tv_debug, яка дозволяє змінювати вид поля дії в режимі роумінгу в GOTV або під час відтворення демо-запису;",
                            "- Включено оптимізація продуктивності областей порталів."]
                    },
                    twenty_seven_ten: {
                        header: "Що не так з ціною CS20 Case?",
                        body: ["CS20 проти Prisma:", "Перший день: CS20 - 10$, Prisma - 22.81$", "Другий день: CS20 - 7.51$, Prisma - 9.81$",
                            "Шостий день: CS20 - 0.64$, Prisma - 4.8$", "Сьомий день: CS20 - 1.77$, Prisma - 4.91$",
                            "Так чому ціна CS20 Case набагато нижча, за ціну Prisma Case?", "Відповідь така: Ми мали 100% шанс дропу CS20 Case в перші 6 днів, а суспільству ксго не потрібно було така велика кількість кейсів.",
                            "Можна побачити, що ціна на CS20 Case зросла в 3 рази на шостий день, тому що 100% шанс випадіння CS20 був забраний."]
                    }
                }
            },
            ru: {
                header: ['Гайд', 'Симулятор Контрактов', 'Новости', 'О Нас', 'Халявные скины'],
                about_us: ['О', 'О Нас',
                    'Мы - группа веб-разработчиков и любителей CS:GO. Приложение, созданное с помощью Vue.js. Это приложение создано, чтобы помочь вам лучше разобраться с CS: GO Контрактами. Если у вас возникли вопросы, не стесняйтесь связаться с нами.',
                    'О программе',
                    "Наше программное обеспечение генерирует почти 37 000 контрактов. Цены берутся из среднемесячных продаж в стиме. На каждом скинни есть ссылка на Bitskins и Steam. На вкладке 'float' вы видите самый низкий флоат, который вы можете получить, если вы засунете 10 скинов из наименее возможным флоат. На вкладке 'Max Input Float' вы можете увидеть самый высокий входной флоат для каждой исходной области. Вы также можете увидеть шанс падения на вкладке 'Chance'. И наконец вкладка 'Average profit' покажет вам, конечно, средний профит от контракта в целом. Вы также можете нажать кнопку стрелки справа, которая переключит контракт на другой."],
                guide: ["Как получить прибыль от CS: GO контрактов", "Что такое контракт?",
                    '" В обмен на 10 предметов одинакового качества вы получите один товар более высокого качества из коллекции один из предоставленных вами предметов. " ', " -Valve ",
                    "По-простому CS: GO Контракт позволяет обменять 10 предметов с одинаковой внешностью на один предмет с более высоким качеством.",
                    "Как работают торговые контракты?",
                    "Прежде всего, вы должны понять, что такое",
                    " флоат. ", "Итак, ", ' флоат ',
                    "- точная зносошенисть скина. Этот показатель колеблется от нуля до единицы, где 0 - наименее возможный флоат. Он также разделен диапазонами, на основе которых ",
                    " качество ", "скина определяется.",
                    "Сегодня контракты базируются на", " выходных скинах",
                    ". Это значит, что если ваш вход содержит ", " 5 ", " одинаковых скинов с ", ' 5 ',
                    "Возможными исходными скинами и другие", "5", "одинаковых скинов с", " 1 ",
                    'Возможен выходным скином, ваши шансы: ', '16, 666%', "на каждый результат (потому что у нас", ' 6 ', ' выходных скинов => ', ' 1/6 = 16,666% ', ') .',
                    "Float может изменить выход. Точная формула: ",
                    "OutputFloat = (MaxFloat - MinFloat) * AVG + MinFloat. ",
                    "AVG = средний флоат ваших входных скинов. ",
                    "MinFloat = минимальный флоат возможной вознаграждения. ", ' MaxFloat = максимальный флоат возможного вознаграждения.',
                    "Что вам нужно, чтобы осуществить торговлю?", "1) Убедитесь, что у вас есть Prime Version CS: GO.",
                    '2) Вам нужно 10 скинов с одинаковым качеством. Если у вас нет скинов, то можете посетить вкладку',
                    "Халявные Скины", ', в которой находятся многие рефелальние коды к наиболее популярным КС:ГО площадкам.',
                    "Вы не можете использовать StatTrak™ и не StatTrak™ скины, в одном контракте.",
                    "Вы также не можете использовать сувениры, ножи, перчатки или скины красного качества.",
                    '3) Выберите 10 скинов с одинаковым качеством и нажмите кнопку " PROCEED...".',
                    "4) Наслаждайтесь результатом."],
                news: {
                    twelve_ten: {
                        header: "Обновление CS:GO от 18.10.2019: CS20 Case, CS20 Sticker Capsule",
                        body: ["Игровые предметы:",
                            "- В CS:GO добавили новый кейс со скинами 'CS20 Case' и капсулу со стикерами 'CS20 Sticker Capsule'.",
                            "Карты:", "- Обновленный Cache теперь официально доступен в режимах 'Обычный', 'Бой на смерть', 'Схватка'.",
                            "Другое:", "- Добавлен параметр запуска игры -lowmemworkshop, который позволяет обновлять очень большие файлы карт в Мастерской Steam;",
                            "- Добавлена команда fov_tv_debug, которая позволяет изменять вид поля действия в режиме роуминга в GOTV или во время воспроизведения демо-записи;",
                            "- Включено оптимизация производительности областей порталов."]
                    },
                    twenty_seven_ten: {
                        header: "Что не так с ценой CS20 Case?",
                        body: ["CS20 против Prisma:", "Первый день: CS20 - 10$, Prisma - 22.81$", "Второй день: CS20 - 7.51$, Prisma - 9.81$",
                            "Шестой день: CS20 - 0.64$, Prisma - 4.8$", "Седьмой день: CS20 - 1.77$, Prisma - 4.91$",
                            "Так почему цена CS20 Case гораздо ниже, цены Prisma Case?", "Ответ таков: Мы имели 100% шанс дропа CS20 Case в первые 6 дней, а обществу ксго не нужно было такое большое количество кейсов.",
                            "Можно увидеть, что цена на CS20 Case выросла в 3 раза в шестой день, потому что 100% шанс выпадения CS20 был убран."]
                    }
                }
            }
        }
    }
});
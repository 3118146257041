<template>
  <div data-aos="fade-in" class="page-wrapper-w-footer">

    <div data-aos="fade-in" class="page-wrapper">

      <h1>Best CSGO Training Maps</h1>
      <p>
        This guide will help you choose the best CSGO training routine. These maps are selected according to regular
        player reviews and preferences of professional players.
        This guide chooses the best maps for every most important game aspects, such as aim, spray control, spray
        transfer, peeks, prefires, grenades and others.
      </p>

      <p class="right"><time datetime="2020-07-03 21:00">03.07.2020</time></p>

      <h2>CSGO Aim Training Map</h2>
      <hr><br>
      <h3>1. Aim Botz - Training</h3>
      <p class="right white">by uLLeticaL</p>
      <hr>
      <div class="map_link">
        <a target="_blank" href="https://steamcommunity.com/sharedfiles/filedetails/?id=243702660">Download Map Link Here.
          Press "Subscribe" to add map.</a>
      </div>
      <p>
        The best map for training aim in CSGO is "Aim Botz - Training". I think you often see when professional players,
        YouTubers and your friends spending hours on improving skill
        on this map. This map offers whole selection of all currently available weapons in CSGO. The best options for
        training your aim on this map are:<br><br>
      <ul>
        <li>Put bots in one quarter of the map and try to kill as many bots as possible in one minute. And over time, try
          to improve your score.<br><br></li>
        <li>Another option for training aim is to choose the option that is responsible for the movement of bots left to
          right. With this setting you have to try aimimg on moving
          targets. Because in most cases in competitive mode you will have to shoot at moving targets.<br><br></li>
        <li>You can also train AWP perfectly on this map. Leave the bots at four quarters and try to make flicks from
          different sides. This practice is often used by the
          best professional AWP players.<br><br>
          On this map you can also change bots hemlet, armor, speed, map's background, place some boxes, crates or even
          turn on metronome to feel your speed.<br><br></li>
      </ul>
      </p>
      <figure>
        <img class="inv-guide-img-first" src="../assets/img/aim_botz1.webp" alt="Aim botz">
        <figcaption>Training Aim on Aim Botz - Training</figcaption>
      </figure>

      <div class="ad-container">
        <InArticleAdsense data-ad-client="ca-pub-8686792985613246" data-ad-slot="2324654782">
        </InArticleAdsense>
      </div>

      <h3>2. Training - Blank</h3>
      <p class="right white">by Zool</p>
      <hr>
      <div class="map_link">
        <a target="_blank" href="https://steamcommunity.com/sharedfiles/filedetails/?id=467786170">Download Map Link Here.
          Press "Subscribe" to add map.</a>
      </div>
      <p>
        Another cool map for training aim in CSGO is "Training | Blank". This map is best suited if you want to improve
        speed and accuracy of aiming at opponents peeking from
        different positions, as well as if you are tired of the usual colors of maps with bots.
        This map offers a wide selection of all currently available weapons in CSGO. The best options for training your
        aim on this map are:
      <ul>
        <li>Stand in the middle of the map and quickly turning in different directions and try to hit peeking
          opponents.<br><br></li>
        <li>Stand near one of the positions from which opponents are peeking and train the reaction and placement of your
          crosshair while enemy peek.<br><br></li>
        <li>Also here it is possible to train holding site with AWP, when enemies are peeking you around the
          corner.<br><br>
          This map also offers a change in the number of bots, their speed, music, and the infinity of ammo.<br><br></li>
      </ul>
      <br><br>
      </p>
      <figure>
        <img class="inv-guide-img-first" src="../assets/img/training-blank.webp" alt="Training Blank">
        <figcaption>Training Aim on Training - Blank</figcaption>
      </figure>

      <div class="ad-container">
        <InArticleAdsense data-ad-client="ca-pub-8686792985613246" data-ad-slot="5636928426">
        </InArticleAdsense>
      </div>

      <h2>CSGO Spray Control Training Map</h2>
      <hr><br>
      <h3>Recoil Master - Spray Training</h3>
      <p class="right white">by uLLeticaL</p>
      <hr>
      <div class="map_link">
        <a target="_blank" href="https://steamcommunity.com/sharedfiles/filedetails/?id=419404847">Download Map Link Here.
          Press "Subscribe" to add map.</a>
      </div>
      <p>
        The best map for training spray control in CSGO is "Recoil Master - Spray Training". On this map you can improve
        your spray control from different guns and
        different distances.
        This map offers a wide selection of all currently available weapons in CSGO. The best ways of training your spray
        on this map are:
        - Press the "Movement Lock" button and at the base distance try your best in spray with the most popular weapons
        in CSGO.
        - If you find it difficult to spray control, you should first start with the control of the first 10 bullets, then
        another 10 and in the end you will hopefully
        will be able to control the whole mag.
        - Improve your spray skill by increasing the distance.
        This map offers a change in distance, change in number of targets, allows you to slow down time, as well as the
        functions of automatic spray and others.
      </p>
      <figure>
        <img class="inv-guide-img-first" src="../assets/img/recoil-master.webp" alt="Recoil Master">
        <figcaption>Training Spray Control on Recoil Master - Spray Training</figcaption>
      </figure>

      <div class="ad-container">
        <InArticleAdsense data-ad-client="ca-pub-8686792985613246" data-ad-slot="9698272997">
        </InArticleAdsense>
      </div>

      <h2>CSGO Smokes, Flashes, Grenades, Prefire, Peek Training Map</h2>
      <hr><br>
      <h3>Yprac Dust2 Guide</h3>
      <p class="right white">by Yesber</p>
      <hr>
      <div class="map_link">
        <a target="_blank" href="https://steamcommunity.com/sharedfiles/filedetails/?id=1202643420">Download Map Link
          Here. Press "Subscribe" to add map.</a>
      </div>
      <p>
        The best map for training smokes, flashes and grenades in CSGO is "Yprac Dust2 Guide". Of course, there are
        another Yprac maps for all competitive maps.
        This map has many functions, which we will consider below.
      </p>
      <figure>
        <img class="inv-guide-img-first" src="../assets/img/yprac-main.webp" alt="Yprac">
        <figcaption>Features of Yprac Dust2 Guide</figcaption>
      </figure>

      <h4>CSGO Smokes, Flashes Training Map - Yprac Dust2 Guide</h4>
      <hr>
      <p>
        On this map you can learn all smokes, flashes and grenades on every single competitive map in CSGO.
        All you need to do is run through different positions on the map. And there will automatically appear points
        from where to throw certain grenades, and also the trajectory of grenades will be visible. After you throw a
        grenade you
        will see how your grenade flies and where it lands. This allows you to understand it was a good grenade or not, as
        well as
        to see your grenade through the eyes of the enemy.
      </p>
      <figure>
        <img class="inv-guide-img-first" src="../assets/img/yprac-smokes.webp" alt="Yprac smokes">
        <figcaption>Training Grandes on Yprac Dust2 Guide</figcaption>
      </figure>

      <h4>CSGO Prefire Training Map - Yprac Dust2 Guide</h4>
      <hr>
      <p>
        If you want to learn how to prefire in your competitive games, then you should pay attention to this map, because
        it is the best of its kind.
        You just have to choose the map you need, download it. Then choose the difficulty, the number of bots and the
        position you want to go through. Now you
        can easy learn how to prefire.
      </p>
      <figure>
        <img class="inv-guide-img-first" src="../assets/img/yprac-prefire.webp" alt="Yprac prefire">
        <figcaption>Training Prefires on Yprac Dust2 Guide</figcaption>
      </figure>

      <p>
        This map for CSGO training also offers other features that are also cool. I advise you to try them. And good luck
        in your competitive games.
      </p>

    </div>

    <FooterComponent class="footer" />

  </div>
</template>

<script>
import FooterComponent from '../components/FooterComponent.vue'

export default {
  name: 'AimTrainingMaps',
  components: {
    FooterComponent
  }
}
</script>

<style scoped>
img {
  width: 40vw;
  height: 40vh;
}

h1 {
  text-align: center;
  margin-top: 3vh;
  margin-bottom: 9vh;
}

h2 {
  margin-top: 7vh;
  text-align: start;
  font-size: 24px;
}

h3 {
  margin-top: 2vh;
  text-align: left;
  font-size: 20px;
}

h4 {
  margin-top: 2vh;
  text-align: left;
  font-size: 19px;
}

p {
  text-align: justify;
  font-size: 18px;
}

a {
  color: white;
}

hr {
  border: 0;
  height: 1px;
  background: #333;
  width: inherit;
  margin-top: 0.5em;
  margin-bottom: 1em;
}

li {
  margin-left: 2vw;
  text-align: start;
}

figure {
  margin: 15px 0;
}

figcaption {
  color: #ffffff;
}

.white {
  color: #ffffff;
}

.right {
  float: right;
}

.map_link {
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.page-wrapper {
  margin: 0 auto;
  margin-bottom: 10vh;
  width: 60vw;
}

.inv-img-wrapper {
  margin: 0 auto;
  display: grid;
}

.inv-img-wrapper2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.inv-guide-img-first {
  width: 35vw;
}

.inv-guide-img {
  width: 28vw;
}

@media only screen and (max-width : 1200px) {
  .page-wrapper {
    width: 80vw;
  }

  .inv-guide-img-first {
    width: 50vw;
  }

  .inv-guide-img {
    width: 35vw;
  }
}

@media only screen and (max-width : 1000px) {
  .page-wrapper {
    padding-bottom: 10vh;
  }

  .footer {
    display: none;
  }

  .inv-guide-img-first {
    width: 70vw;
  }
}</style>

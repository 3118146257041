<template>
  <div>
    <div class="header-wrap grid">

      <div id="navbar">
        <HamburgerComponent v-if="window.width < 900" />
        <h2 id="main_logo">
          <a href="/" class="header-button" id="main_logo">
            <span>Trade</span><span id="UP">UP</span><br>
            <span id="academy">Academy</span>
          </a>
        </h2>

        <div v-if="window.width > 900" class="header-button top_content">
          <div>{{ 'Trade Ups' }}</div>
          <div class="drpdwn-content drpdwn-content-width1">
            <a class="drpdwn-a" :href="'/guide/' + this.$store.state.lang">Guide</a>
            <a href="/" class="drpdwn-a">Calculator</a>
          </div>
        </div>
        <div v-if="window.width > 900" class="header-button top_content">
          <div>{{ 'Investments' }}</div>
          <div class="drpdwn-content drpdwn-content-width2">
            <a href="/invest-calculator/guide" class="drpdwn-a">Guide</a>
            <!-- <a href="/invest-calculator" class="drpdwn-a">Calculator</a> -->
          </div>
        </div>
        <a v-if="window.width > 900" :href="'/news/' + this.$store.state.lang" class="header-button" id="news">{{
          content[2] }}</a>
        <a v-if="window.width > 900" :href="'/about/' + this.$store.state.lang" class="header-button" id="about-us">{{
          content[3] }}</a>
        <div v-if="window.width > 900" class="header-button header-button free_header top_content">
          <div>{{ 'Free Skins' }}</div>
          <div class="drpdwn-content drpdwn-content-width2">
            <a href="/free_skins" class="drpdwn-a">All Sites</a>
            <a href="/csgo-roll-code" class="drpdwn-a">CSGORoll</a>
          </div>
        </div>
        <!-- <a v-if="window.width > 900" :href="'/skin_viewer'" class="header-button" id="skin-viewer">3D Skin Viewer</a> -->
      </div>
    </div>
  </div>
</template>

<script>
import HamburgerComponent from './HamburgerComponent.vue'

export default {
  name: 'HeaderComponent',
  components: {
    HamburgerComponent
  },
  data() {
    return {
      window: {
        width: 0,
      },
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth
    }
  },
  computed: {
    content() {
      return this.$store.state.content[this.$store.state.lang].header
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
* {
  margin: 0;
  padding: 0;
}
</style>

<style scoped>
a {
  color: white;
  text-decoration: none;
}

.header-wrap {
  align-items: center;
  height: 3em;
}

#navbar {
  margin-left: 2vw;
  display: grid;
  grid-template-columns: 2fr repeat(6, auto) 2fr;
  align-content: center;
  font-weight: 600;
  font-size: 1em;
}

.top_content {
  align-items: center;
  transition: background-color 0.15s ease-out;
}

.top_content:hover {
  background-color: #1e2021;
  cursor: pointer;
}

.dropdown-content {
  visibility: hidden;
  position: absolute;
  margin-top: 40px;
  z-index: 1;
}

.logged_wrapper:hover .dropdown-content {
  visibility: visible;
}


.google_logout {
  font-family: 'Karla', 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: 600;
  background-color: #1e2021;
  padding: 8px 0px;
  border-top: 1px solid #515558;
  border-bottom: none;
  border-left: none;
  border-right: none;
  color: #ffffff;
}

.drpdwn-a {
  padding-top: 7px;
  padding-bottom: 7px;
  border-top: 1px solid #515558;
  border-bottom: none;
  border-left: none;
  border-right: none;
  background-color: #1e2021;
}

.drpdwn-a:hover {
  background-color: #26282a;
}

.drpdwn-content {
  margin-left: -1.5vw;
  margin-top: 14px;
  width: 100%;
  display: none;
  position: absolute;
  z-index: 1;
}

.drpdwn-content a {
  display: block;
}

.top_content:hover .drpdwn-content {
  display: block;
}

.google_logout:hover {
  cursor: pointer;
  background-color: #26282a;
}

.google_nick {
  margin-left: 1vw;
  margin-right: 1vw;
}

.google_ava {
  border-radius: 50%;
  margin-right: 0.5vw;
}

#main_logo {
  display: block;
  align-self: center;
  font-size: 1em;
  padding: 0;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  line-height: 1.2;
  transition: color 0.5s ease;
}

#main_logo:hover {
  color: #b8aa7b;
}

#academy {
  margin-left: 1em;
}

#UP {
  font-size: 1.1em;
  color: #c9593a;
}

.header-button {
  position: relative;
  align-items: center;
  justify-self: center;
  padding: 15px 1.5vw;
  color: #fff;
}

.free_header {
  color: red;
}

@media only screen and (max-width : 1200px) {
  #navbar {
    font-size: 0.8em;
  }
}

@media only screen and (max-width : 1000px) {
  #navbar {
    grid-gap: 3em;
  }
}

@media only screen and (max-width : 900px) {
  #navbar {
    grid-template-columns: 10px 1fr 1fr;
    font-size: 0.8em;
  }
}</style>

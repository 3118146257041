<template>
  <div class="output">

    <div class="main-inf">
      <span @click="copyToClipboard(name1)"><img src="../assets/img/copy-7ec847ec.svg" alt="copy"></span>
      <p class="output-type">{{ name1.substring(0, name1.indexOf('|') - 1) }}</p>
      <p class="output-name">{{ name1.substring(name1.indexOf('|') + 2, name1.indexOf('(') - 1) }}</p>
      <p class="output-ex" v-bind:style="{ color: setExtColor }">{{ name1.substring(name1.indexOf('(') + 1, name1.length
        -
        1) }}</p>
    </div>

    <img class="output-img" v-bind:src="'https://steamcommunity-a.akamaihd.net/economy/image/' + icon_url1"
      :alt="this.name1">

    <div class="add-inf">
      <p class="output-float">Float: <span>{{ float1 }}</span></p>
      <p class="output-price">Steam price: <span>{{ price1 }}$</span></p>
      <p class="output-chance">Chance: <span>{{ chance }}%</span></p>
      <p class="output-profit">Profit: <span>x{{ percent1 }}</span></p>
      <div class="buy_links_wrapper">
        <a target="_blank" :href="`https://steamcommunity.com/market/listings/730/${this.name1}`">
          <img class="steam" src="@/assets/img/Steam_icon_logo.svg" alt="steam" style="cursor:pointer;">
        </a>
        <a target="_blank"
          :href="`https://bitskins.com/market/cs2?search={%22where%22:{%22skin_name%22:%22${this.name1}%22}}&sort_by=price&ref_alias=tradeup`">
          <img class="bitskins" src="@/assets/img/bitskins-icon.png" alt="biskins" style="cursor:pointer;">
        </a>
        <a target="_blank" :href="`https://dmarket.com/ua/ingame-items/item-list/csgo-skins?ref=FAC5LymqtT&title=${this.name10}`">
          <img class="dmarket_icon" width="25px" src="@/assets/img/dma.png" alt="dmarket" style="cursor:pointer;">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OutputSkin',
  props: {
    n1: {
      type: String
    },
    p1: {
      type: Number
    },
    pr1: {
      type: Number
    },
    f1: {
      type: String
    },
    max_input_f: {
      type: Number
    },
    i_url1: {
      type: String
    },
    ch: {
      type: Number
    }
  },
  data() {
    return {
      name1: '',
      price1: 0,
      percent1: 0,
      float1: '',
      max_input_float: 0,
      icon_url1: '',
      color: '',
      chance: 0
    }
  },
  methods: {
    copyToClipboard(text) {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      console.log('Text copied to clipboard');
    },
  },
  computed: {
    setExtColor: function () {
      switch (this.name1.substring(this.name1.indexOf('(') + 1, this.name1.length - 1)) {
        case 'Factory New':
          return 'lime'
        case 'Minimal Wear':
          return '#83b200'
        case 'Field-Tested':
          return 'yellow'
        case 'Well-Worn':
          return 'orange'
        case 'Battle-Scarred':
          return 'red'
        default:
          return 'lime';
      }
    }
  },
  watch: {
    n1: function () {
      this.name1 = this.n1
    },
    p1: function () {
      this.price1 = this.p1
    },
    f1: function () {
      this.float1 = this.f1
    },
    max_input_f: function () {
      this.max_input_float = this.max_input_f
    },
    pr1: function () {
      this.percent1 = this.pr1.toFixed(2)
    },
    i_url1: function () {
      this.icon_url1 = this.i_url1
    },
    ch: function () {
      this.chance = this.ch
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  color: white;
}

.output {
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
  background-color: rgba(28, 30, 34, 0.8);
}

a {
  display: block;
  color: #2c3e50;
}

img {
  padding: 0 3px 0 3px;
}

.output-img {
  width: 200px;
  height: 150px;
  padding-top: 10px;
  padding-bottom: 10px;
  object-fit: cover;
  padding-left: 10px;
  padding-right: 10px;
}

.output-wrapper>div {
  font-family: 'Karla', sans-serif;
}

.main-inf {
  position: relative;
  padding: 10px 10px 0 10px;
  font-size: 14px;
  background-color: rgba(28, 30, 34, 0.8);
  width: 200px;
}

.main-inf>span {
  cursor: pointer;
  position: absolute;
  right: 10px;
}

.main-inf>span>img {
  height: 20px;
}

.add-inf {
  width: 200px;
  padding: 10px 10px 10px 10px;
  font-size: 15px;
  font-weight: 600;
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 8px 6px -6px black;
  border-top: 1px solid #535353;
}

span {
  font-weight: normal;
}

.max_input_float {
  background: linear-gradient(315deg, #B82E1F 0%, #F2C17D 74%);
  background-size: 300% 300%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: AnimatedText 3s ease infinite;
  -moz-animation: AnimatedText 3s ease infinite;
  -o-animation: AnimatedText 3s ease infinite;
  animation: AnimatedText 3s ease infinite;
  font-size: 1.1em;
  font-weight: bold;
}

@keyframes AnimatedText {
  0% {
    background-position: 0% 51%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 51%
  }
}

.output-type {
  font-weight: 800;
  font-size: 16px;
}

.output-ex {
  margin-top: 5px;
  font-size: 15px;
}

img {
  padding: 0;
  padding-top: 5px;
  margin: 0;
  width: 25px;
}

.steam {
  margin-right: 5px;
}

.buy_links_wrapper {
  display: flex;
  height: 25px;
  width: max-content;
  grid-gap: 0px;
  margin-bottom: 4px;
}

.bitskins_img {
  transform: scale(1.1);
}

.dmarket_icon {
  margin-top: -1px;
  margin-left: 5px;
  transform: scale(0.88);
}

/*==========  Desktop First  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1500px) {
  .output-img {
    width: 150px;
    height: calc(150px / 1.33333);
  }

  .main-inf {
    font-size: 12px;
    width: 150px;
  }

  .add-inf {
    width: 150px;
    font-size: 13px;
  }

  .output-type {
    font-size: 13px;
  }

  .output-ex {
    font-size: 12px;
  }

  img {
    width: 22px;
  }
}

@media only screen and (max-width : 1200px) {
  .output-img {
    width: 130px;
    height: calc(130px / 1.33333);
  }

  .main-inf {
    font-size: 11px;
    width: 130px;
  }

  .add-inf {
    width: 130px;
    font-size: 12px;
  }

  .output-type {
    font-size: 11px;
  }

  .output-ex {
    font-size: 10px;
  }

  img {
    width: 20px;
  }

  .max_input_float {
    font-size: 1em;
  }
}




/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
  .output-img {
    width: 120px;
    height: calc(120px / 1.33333);
  }

  .main-inf {
    font-size: 10px;
    width: 120px;
  }

  .add-inf {
    width: 120px;
    font-size: 11px;
  }

  .output-type {
    font-size: 10px;
  }

  .output-ex {
    font-size: 9px;
  }

  img {
    width: 18px;
  }
}

@media only screen and (min-width : 3200px) {
  .output-img {
    padding-right: 30px;
    width: 360px;
    height: calc(360px / 1.33333);
  }

  .main-inf {
    padding: 20px 20px 0 20px;
    font-size: 30px;
    width: 360px;
  }

  .add-inf {
    padding: 20px 20px 20px 20px;
    width: 360px;
    font-size: 33px;
  }

  .output-type {
    font-size: 30px;
  }

  .output-ex {
    font-size: 27px;
  }

  img {
    width: 54px;
  }

  .output {
    margin-right: 30px;
    margin-left: 30px;
    margin-bottom: 60px;
  }
}

@media only screen and (max-height : 900px) {
  .output-img {
    width: 120px;
    height: calc(120px / 1.33333);
  }

  .main-inf {
    font-size: 10px;
    width: 120px;
  }

  .add-inf {
    width: 120px;
    font-size: 11px;
  }

  .output-type {
    font-size: 10px;
  }

  .output-ex {
    font-size: 9px;
  }

  img {
    width: 18px;
  }
}
</style>

<template>
  <div class="page-wrapper-w-footer">

    <div class="page-wrapper">

      <h1>CSGO Ranks List and CSGO Ranking System Guide</h1>
      <p>
        The CSGO ranking system is very important part of a Game, because this system helps a player to understand how
        good he is. Allows CSGO players
        to compete with equal-skilled opponents in a Match Making. Gives an incentive to get better in the game and reach
        the highest ranks. This system
        has certain features, which we will discuss in this guide.
      </p>

      <p class="right"><time datetime="2020-07-05 12:00">05.07.2020</time></p>

      <h2>CSGO Match Making Ranks List</h2>
      <hr>
      <ul>
        <ol style="margin-top: 30px;"><img class="img-rank" src="../assets/img/Silver-1.jpg" alt="Silver-1"><span
            class="rank-span">1. Silver I CSGO Rank</span></ol>
        <ol><img class="img-rank" src="../assets/img/Silver-2.jpg" alt="Silver-2"><span class="rank-span">2. Silver 2 CSGO
            Rank</span></ol>
        <ol><img class="img-rank" src="../assets/img/Silver-3.jpg" alt="Silver-3"><span class="rank-span">3. Silver III
            CSGO Rank</span></ol>
        <ol><img class="img-rank" src="../assets/img/Silver-4.jpg" alt="Silver-4"><span class="rank-span">4. Silver IV
            CSGO Rank</span></ol>
        <ol><img class="img-rank" src="../assets/img/Silver-5.jpg" alt="Silver-5"><span class="rank-span">5. Silver Elite
            CSGO Rank</span></ol>
        <ol><img class="img-rank" src="../assets/img/Silver-6.jpg" alt="Silver-6"><span class="rank-span">6. Silver Elite
            Master CSGO Rank</span></ol>
        <ol><img class="img-rank" src="../assets/img/Gold-Nova-1.jpg" alt="Gold-Nova-1"><span class="rank-span">7. Gold
            Nova I CSGO Rank</span></ol>
        <ol><img class="img-rank" src="../assets/img/Gold-Nova-2.jpg" alt="Gold-Nova-2"><span class="rank-span">8. Gold
            Nova II CSGO Rank</span></ol>
        <ol><img class="img-rank" src="../assets/img/Gold-Nova-3.jpg" alt="Gold-Nova-3"><span class="rank-span">9. Gold
            Nova III CSGO Rank</span></ol>
        <ol><img class="img-rank" src="../assets/img/Gold-Nova-4.jpg" alt="Gold-Nova-4"><span class="rank-span">10. Gold
            Nova Master CSGO Rank</span></ol>
        <ol><img class="img-rank" src="../assets/img/Master-Guardian-1.jpg" alt="Master-Guardian-1"><span
            class="rank-span">11. Master Guardian I CSGO Rank</span></ol>
        <ol><img class="img-rank" src="../assets/img/Master-Guardian-2.jpg" alt="Master-Guardian-2"><span
            class="rank-span">12. Master Guardian II CSGO Rank</span></ol>
        <ol><img class="img-rank" src="../assets/img/Master-Guardian-3.jpg" alt="Master-Guardian-3"><span
            class="rank-span">13. Master Guardian Elite CSGO Rank</span></ol>
        <ol><img class="img-rank" src="../assets/img/Distinguished-Master-Guardian-4.jpg"
            alt="Distinguished-Master-Guardian-4"><span class="rank-span">14. Distinguished Master Guardian CSGO
            Rank</span></ol>
        <ol><img class="img-rank" src="../assets/img/Legendary-Eagly.jpg" alt="Legendary-Eagly"><span
            class="rank-span">15. Legendary Eagl CSGO Ranke</span></ol>
        <ol><img class="img-rank" src="../assets/img/Legendary-Eagly-Master.jpg" alt="Legendary-Eagly-Master"><span
            class="rank-span">16. Legendary Eagle Master CSGO Rank</span></ol>
        <ol><img class="img-rank" src="../assets/img/Supreme-Masters-First-Class.jpg"
            alt="Supreme-Masters-First-Class"><span class="rank-span">17. Supreme Master First Class CSGO Rank</span></ol>
        <ol><img class="img-rank" src="../assets/img/The-Global-Elite.jpg" alt="The-Global-Elite"><span
            class="rank-span">18. Supreme Master First Class CSGO Rank</span></ol>
      </ul>

      <h2>CSGO Match Making Ranks Distribution</h2>
      <hr>
      <p>
        First of all, you need to understand that Rank in CSGO is needed to choose opponents and teammates with
        approximately close level of play skill in the Competitive Mode.
        Therefore, you should not chase in rank race, because at higher ranks it will be very difficult to play. Every
        newbie in CSGO begins his rank journey
        with Silver I rank and gradually improving his aim, straifs, prefires, grenades and knowledge of Competitive Maps
        achives better ranks. You can find the best maps
        for training your skill in CSGO <a target="_blank" href="/best-csgo-training-maps">here</a>. <br><br>

        Let's look at distribution of ranks among CSGO players. As you can see, the average rank in this game is Gold
        Nova. And the percentage of players with the
        highest rank is very small and equal to 0.69% among all players. So, in CSGO there are 31.52% Silvers - low rank
        or rank for beginners, 34.77% Gold Novas - middle rank,
        19.63% Master Guardians - rank above average and 14.07% of players with the highest rank category.
      </p>
      <ul class="ranks-distribution">
        <ol><img class="img-rank" src="../assets/img/Silver-1.jpg" alt="Silver-1 percent"><span class="rank-span"> -
            3.9%</span></ol>
        <ol><img class="img-rank" src="../assets/img/Silver-2.jpg" alt="Silver-2 percent"><span class="rank-span"> -
            4.07%</span></ol>
        <ol><img class="img-rank" src="../assets/img/Silver-3.jpg" alt="Silver-3 percent"><span class="rank-span"> -
            4.05%</span></ol>
        <ol><img class="img-rank" src="../assets/img/Silver-4.jpg" alt="Silver-4 percent"><span class="rank-span"> -
            5.45%</span></ol>
        <ol><img class="img-rank" src="../assets/img/Silver-5.jpg" alt="Silver-5 percent"><span class="rank-span"> -
            6.41%</span></ol>
        <ol><img class="img-rank" src="../assets/img/Silver-6.jpg" alt="Silver-6 percent"><span class="rank-span"> -
            7.64%</span></ol>
        <ol><img class="img-rank" src="../assets/img/Gold-Nova-1.jpg" alt="Gold-Nova-1 percent"><span class="rank-span"> -
            8.58%</span></ol>
        <ol><img class="img-rank" src="../assets/img/Gold-Nova-2.jpg" alt="Gold-Nova-2 percent"><span class="rank-span"> -
            8.84%</span></ol>
        <ol><img class="img-rank" src="../assets/img/Gold-Nova-3.jpg" alt="Gold-Nova-3 percent"><span class="rank-span"> -
            8.97%</span></ol>
        <ol><img class="img-rank" src="../assets/img/Gold-Nova-4.jpg" alt="Gold-Nova-4 percent"><span class="rank-span"> -
            8.38%</span></ol>
        <ol><img class="img-rank" src="../assets/img/Master-Guardian-1.jpg" alt="Master-Guardian-1 percent"><span
            class="rank-span"> - 7.44%</span></ol>
        <ol><img class="img-rank" src="../assets/img/Master-Guardian-2.jpg" alt="Master-Guardian-2 percent"><span
            class="rank-span"> - 6.80%</span></ol>
        <ol><img class="img-rank" src="../assets/img/Master-Guardian-3.jpg" alt="Master-Guardian-3 percent"><span
            class="rank-span"> - 5.39%</span></ol>
        <ol><img class="img-rank" src="../assets/img/Distinguished-Master-Guardian-4.jpg"
            alt="Distinguished-Master-Guardian-4 percent"><span class="rank-span"> - 4.38%</span></ol>
        <ol><img class="img-rank" src="../assets/img/Legendary-Eagly.jpg" alt="Legendary-Eagly percent"><span
            class="rank-span"> - 3.14%</span></ol>
        <ol><img class="img-rank" src="../assets/img/Legendary-Eagly-Master.jpg"
            alt="Legendary-Eagly-Master percent"><span class="rank-span"> - 3.27%</span></ol>
        <ol><img class="img-rank" src="../assets/img/Supreme-Masters-First-Class.jpg"
            alt="Supreme-Masters-First-Class percent"><span class="rank-span"> - 2.59%</span></ol>
        <ol><img class="img-rank" src="../assets/img/The-Global-Elite.jpg" alt="The-Global-Elite percent"><span
            class="rank-span"> - 0.69%</span></ol>
      </ul>

      <h2>How to get Rank in CSGO? First Rank in CSGO.</h2>
      <hr>
      <p>
        After the first game launch you will not have any rank. In order to receive one in CSGO you must do following
        things:<br>
      <ul class="receive-rank-ul">
        <li>Achieve second Experience Level in CS: GO. In order to get it you will need to play 3-5 DeathMatches.</li>
        <li>Play 10 calibration matches.</li>
        <li>You will be given a rank according to results of your gameі during Calibration Matches.</li>
        <li>Usually the first rank cannot be the highest one. The maximum one is probably Legendary Eagle Master, even if
          you win all 10 matches on the first
          place in your team.</li>
      </ul>
      Typically, after a Rank Calibration, new CSGO player receives a Silver II rank.<br>
      </p>

      <h2>How to Rank Up in CSGO? How to get Global Elite?</h2>
      <hr>
      <p>
        In order to raise your rank in CSGO you should follow these main rules. These rules, tested over the years and
        created by professional CSGO players and can be
        considered the best guide for raising
        the rank in KSGO:
      <ul class="receive-rank-ul">
        <li>At the beginning of each game day, spend about ten minutes on Training Map with bots.</li>
        <li>Most of the time, train weapons you like to play with the most. But don't train your aim with only one weapon.
          This mistake usually
          becomes fatal and the players who make it can play for only one side.</li>
        <li>Also do not forget about training spray with the main types of weapons. Because often, especially when you
          need to do more than one kill, the best
          option is to spray, not to tap.</li>
        <li>Learn the mechanics of movements and straifs. This is especially helpful when using pistols and Desert Eagle.
        </li>
        <li>You should also study main grenades on your favourite maps. Do not focus only on smokes, because often an
          instant flash grenade can win you a round.</li>
        <li>Train quick response on the map with the help of retake mode. The main thing when playing in this mode is not
          to rush everyone, but to play as if you were
          playing in a real Competitive Match.</li>
        <li>Spend enough time on DM servers. There you can best understand the mechanics of the game and learn how to win
          every single duel.</li>
        <li>You should also study how Game Economy works. To know, when to Full Buy, when to do Force Round, when to do
          Eco round, when to drop teammate a weapon. Which weapons
          are best to play against certain rounds and other aspects.</li>
        <li>You should also watch Competitive Matches of professional teams in CSGO from time to time. When watching such
          matches, you can understand the best tactics,
          grenades, play style and what decisions should be made in certain situations.</li>
      </ul>
      </p>

      <h2>How CSGO Ranking System Works?</h2>
      <hr>
      <p>
        Ranking System in CSGO is quite simple and fair and includes the following factors:
      <ul class="rank-works-ul">
        <ol style="margin-bottom: 15px; font-size: 1.3rem"><strong>Good factors:</strong></ol>
        <ol style="margin-bottom: 15px; font-size: 1.3rem"><strong>Bad factors:</strong></ol>
        <li>Wins in Match Making</li>
        <li>Defeats in Match Making</li>
        <li>Wins with a big difference in rounds</li>
        <li>Defeats with a big difference in rounds</li>
        <li>Large ADR (Average damage per round)</li>
        <li>Low ADR</li>
        <li>High number of kills</li>
        <li>Low number of kills</li>
        <li>Good KD (kill / death)</li>
        <li>A large number of deaths</li>
        <li>A large number of MVPs</li>
        <li>Flashing teammates</li>
        <li>Number of Bomb plants</li>
        <li>Standing AFK</li>
        <li>Bomb Defusing</li>
        <li>Team kills, team damage</li>
        <li>Assassination of the Bomb Carrier</li>
        <li>Dying as Bomb Carrier</li>
        <li>Droping Weapons to Teammates</li>
        <li>Don't Drop Weapons to Teammates</li>
        <li>High percentage of headshots</li>
        <li>Low percentage of headshots</li>
      </ul><br>
      Also in CSGO there is such system as ELO points of each player. This system is adapted to the ranking system. That
      is, when you kill an opponent with a
      larger ELO, you are given more rank-raising points than when you kill an opponent with a smaller ELO.
      </p>

      <h2>CSGO Ranking System FAQ</h2>
      <hr>
      <p>
        How many ranks are there in CSGO?<br><br>
        <i>- There are 18 Match Making Ranks in CSGO. The lowest rank is Silver 1, the highest one is Global
          Elite.</i><br><br>
        Am I a very bad player if my rank is Silver?<br><br>
        <i>- No, it's only a matter of time. If you practice a little and learn the basic aspects of the game, it's easy
          to fix.</i><br><br>
        Who are Smurfs in CSGO?<br><br>
        <i>- Smurfs are experienced players who create new accounts and play against newbies.</i><br><br>
        Why can't I play Match Making with my friends?<br><br>
        <i>- Because your ranks are very different. To play with friends in Match Making you need to create party of 5
          people or have approximately the same ranks.</i><br><br><br>

        I hope this CSGO Ranks Tutorial will help you to achieve high rank in CSGO. Best regards, Dima.<br>
      </p>

    </div>

    <FooterComponent class="footer" />

  </div>
</template>

<script>
import FooterComponent from '../components/FooterComponent.vue'

export default {
  name: 'CSGORanks',
  components: {
    FooterComponent
  }
}
</script>

<style scoped>
img {
  width: 40vw;
}

h1 {
  text-align: center;
  margin-top: 3vh;
  margin-bottom: 9vh;
}

h2 {
  margin-top: 7vh;
  text-align: start;
  font-size: 24px;
}

h3 {
  margin-top: 2vh;
  text-align: left;
  font-size: 20px;
}

h4 {
  margin-top: 2vh;
  text-align: left;
  font-size: 19px;
}

p {
  text-align: justify;
  font-size: 18px;
}

a {
  color: white;
}

hr {
  border: 0;
  height: 1px;
  background: #333;
  width: inherit;
  margin-top: 0.5em;
  margin-bottom: 1em;
}

ul {
  text-align: start;
}

.ranks-distribution {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 20px;
}

.rank-works-ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 20px;
}

.receive-rank-ul>li {
  margin: 20px 0;
  margin-left: 30px;
}

.rank-works-ul>li {
  margin: 10px 0;
  margin-left: 30px;
}

ul>ol {
  margin: 1.5vh 0;
}

.rank-works-ul>ol {
  margin-left: 15px;
}

li {
  margin-left: 2vw;
  text-align: start;
}

figure {
  margin: 15px 0;
}

figcaption {
  color: #ffffff;
}

.white {
  color: #ffffff;
}

.right {
  float: right;
}

.map_link {
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.page-wrapper {
  margin: 0 auto;
  margin-bottom: 10vh;
  width: 60vw;
}

.img-rank {
  width: 70px;
  height: 30px;
  margin-right: 20px;
  vertical-align: middle;
}

.inv-img-wrapper {
  margin: 0 auto;
  display: grid;
}

.inv-img-wrapper2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.inv-guide-img-first {
  width: 35vw;
}

.inv-guide-img {
  width: 28vw;
}

@media only screen and (max-width : 1200px) {
  .page-wrapper {
    width: 80vw;
  }

  .inv-guide-img-first {
    width: 50vw;
  }
}

@media only screen and (max-width : 1000px) {
  .page-wrapper {
    padding-bottom: 10vh;
  }

  .footer {
    display: none;
  }

  .inv-guide-img-first {
    width: 80vw;
  }
}</style>

<template>
    <div data-aos="fade-in" class="news-container">
        <div class="news-block">
            <div class="news-header first" @click="hide_content1">
                <h3 class="news-header_h3">{{ content.twenty_seven_ten.header }}</h3>
                <p class="news-date">27.10.2019</p>
            </div>
            <div class="news-body">
                <img id="new_cache_img" width="25%" style="margin-left: 10%" src="../../assets/img/cs20_c.png"
                    alt="CS20 Stricker Capsule">
                <h3 class="bold">{{ content.twenty_seven_ten.body[0] }}</h3><br>
                <p>&middot; {{ content.twenty_seven_ten.body[1] }}</p>
                <p>&middot; {{ content.twenty_seven_ten.body[2] }}</p>
                <p>&middot; {{ content.twenty_seven_ten.body[3] }}</p>
                <p>&middot; {{ content.twenty_seven_ten.body[4] }}</p><br>
                <h3 class="bold">{{ content.twenty_seven_ten.body[5] }}</h3>
                <p style="margin-top: 25px" class="delete_line_height">{{ content.twenty_seven_ten.body[6] }}</p>
                <p style="margin-top: 15px" class="delete_line_height">{{ content.twenty_seven_ten.body[7] }}</p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'NewsComponent',
    computed: {
        content() {
            return this.$store.state.content[this.$store.state.lang].news
        }
    },
    methods: {
        hide_content1() {
            let content = document.querySelector(".news-body")
            if (content.style.display === '' || content.style.display === 'block') {
                content.style.display = 'none'
            } else {
                content.style.display = 'block'
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
    padding: 0;
    margin: 0;
}

.bold {
    font-weight: bold;
}

.news-container {
    padding-top: 10vh;
    font-size: 1em;
    text-align: start;
}

.news-block {
    margin: 0 auto;
    width: 50vw;
    padding-bottom: 100px;
}

.news-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 0.5em;
    border-bottom: 1px solid black;
    font-size: 1.5rem;
    font-weight: bold;
}

.news-header:hover {
    cursor: pointer;
}

.news-header_h3 {
    font-size: 25px;
    width: 60%;
    font-weight: 600;
}

.news-date {
    font-weight: normal;
}

.news-body {
    margin-top: 20px;
}

.news-body>p {
    font-size: 18px;
    line-height: 2;
}

.delete_line_height {
    line-height: 1.1 !important;
}

@media only screen and (max-width : 1000px) {
    .news-block {
        width: 80vw;
    }

    .news-header_h3 {
        font-size: 25px;
        width: 60%;
        font-weight: 600;
    }

    .news-body {
        margin-top: 10px;
    }

    .news-body>p {
        font-size: 18px;
        line-height: 2;
    }
}</style>

<template>
  <div data-aos="fade-in" class="page-wrapper-w-footer">
    <div data-aos="fade-in" class="page-wrapper">
      <h1>CSGORoll Free Promo Codes</h1>
      <div data-aos="fade-up" class="container">
        <h2>How to get free 3 Skin Cases On CSGO Roll?</h2>
        <hr>
        <p>
          You can use one of free codes listed below:
        </p>
        <ul class="referral-codes_list">
          <li class="referral-code">
            <a href="https://csgoroll.com/r/TAC">
              <span class="referral-code_name">tac</span>
            </a>
            &nbsp;&nbsp;-&nbsp;&nbsp;
            <span class="referral-code_reward">Activate Now For
              <span class="gradient-text">3 Free Cases</span>
            </span>
            <img class="copy_icon" src="../../assets/img/copy-regular.svg" alt="copy code" @click="copy('tac')">
          </li>
          <li class="referral-code">
            <a href="https://csgoroll.com/r/TRADEACADEMY">
              <span class="referral-code_name">tradeacademy</span>
            </a>
            &nbsp;&nbsp;-&nbsp;&nbsp;
            <span class="referral-code_reward">Activate Now For
              <span class="gradient-text">3 Free Cases</span>
            </span>
            <img class="copy_icon" src="../../assets/img/copy-regular.svg" alt="copy code" @click="copy('tradeacademy')">
          </li>
          <li class="referral-code">
            <a href="https://csgoroll.com/r/GLOBALELITE">
              <span class="referral-code_name">globalelite</span>
            </a>
            &nbsp;&nbsp;-&nbsp;&nbsp;
            <span class="referral-code_reward">Activate Now For
              <span class="gradient-text">3 Free Cases</span>
            </span>
            <img class="copy_icon" src="../../assets/img/copy-regular.svg" alt="copy code" @click="copy('globalelite')">
          </li>
        </ul>
        <h2>CSGORoll Free Daily Cases. Level reward system.</h2>
        <hr>
        <p>
          On CSGORoll users can users can play games like russian roulette, crash, dice, unbox cases for skins.
          CSGORoll also offers an excellent daily level rewards system. You can get free daily csgo cases if you rich
          certain XP level.
          Cases are improved at the following levels: 2 level, 10 level, 20 level, 30 level, 40 level, 50 level, 60 level,
          70 level, 80 level, 90 level, 100 level.
          For example, at 100 level the worst skin in the case is AWP | Oni Taiji (Farctory New) for $ 180, and the best
          skin is AWP | Dragon Lore (Factory New) for $ 9500.
          Imagine at 100 level you can get a good CS:GO knife every day. To gain a level you need to fill in XP points.
          The easiest way to fill in these points is playing
          Red / Black russian roulette. But if you are a pro in other game modes, such as: crash, dice, unboxing cases,
          then it's even better.
        </p>
        <div class="inv-img-wrapper">
          <figure>
            <img class="inv-guide-img-first" src="../../assets/img/csgoroll0.png" alt="IBuyPower 2014">
            <figcaption>CSGORoll Free Promo / Referral Code</figcaption>
          </figure>
        </div>
        <h2>How to activate Promo / Referral code on CSGORoll?</h2>
        <hr>
        <p>
          There are 2 options to activate the code on the KSGO role:
        <ul>
          <li>Go to the direct
            <a href="https://csgoroll.com/r/TAC">
              <span class="referral-code_name">link</span>
            </a>
            . So the code is automatically activated and you can get your free cases. To open the
            cases you need to log in via the Steam platform and go to the "Free Box" tab. There you
            can open your free cases.
          </li>
          <li>
            Register for a role. Go to
            <a href="https://www.csgoroll.com/en/boxes/daily-free">
              <span class="referral-code_name">Free Cases Page</span>
            </a>
            . Enter the code "tac". And open your free cases.
          </li>
        </ul>
        </p>
        <div class="inv-img-wrapper">
          <figure>
            <img class="inv-guide-img-second" src="../../assets/img/csgoroll1.png" alt="IBuyPower 2014">
            <figcaption>Activating Promo / Referral Code directly on CSGORoll</figcaption>
          </figure>
        </div>
      </div>
    </div>
    <FooterComponent class="footer" />
  </div>
</template>

<script>
import FooterComponent from '../../components/FooterComponent.vue'

export default {
  name: 'InvestmentGuide',
  components: {
    FooterComponent
  },
  methods: {
    fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;
      textArea.style.position = "fixed";  //avoid scrolling to bottom
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
      } catch (err) {
        console.log(err)
      }
      document.body.removeChild(textArea);
    },
    copy(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(function () {
      }, function (err) {
        console.error('Async: Could not copy text: ', err);
      });
    }
  }
}
</script>

<style scoped>
h1 {
  text-align: center;
  margin-top: 3vh;
  margin-bottom: 9vh;
}

h2 {
  margin-top: 7vh;
  text-align: start;
  font-size: 26px;
}

h3 {
  margin-top: 2vh;
  text-align: left;
  font-size: 22px;
}

h4 {
  margin-top: 2vh;
  text-align: left;
  font-size: 20px;
}

p {
  text-align: justify;
  font-size: 18px;
}

hr {
  border: 0;
  height: 1px;
  background: #333;
  width: inherit;
  margin-top: 0.5em;
  margin-bottom: 1em;
}

a {
  color: white;
}

li {
  margin-top: 10px;
  font-size: 20px;
  margin-left: 2vw;
  text-align: start;
}

figure {
  margin: 15px 0;
}

figcaption {
  color: #ffffff;
}

.white {
  color: #ffffff;
}

.page-wrapper {
  margin: 0 auto;
  margin-bottom: 10vh;
  width: 60vw;
}

.inv-img-wrapper {
  margin: 0 auto;
  display: grid;
}

.inv-img-wrapper2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.inv-guide-img-first {
  width: 25vw;
}

.inv-guide-img-second {
  width: 50vw;
}

.inv-guide-img {
  width: 28vw;
}

.referral-code {
  margin-top: 10px;
  font-size: 20px;
}

.referral-codes_list {
  margin-bottom: 20px;
}

.referral-code_reward {
  font-size: 18px;
}

.copy_icon {
  margin-left: 10px;
  height: 20px;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.25s ease-out;
}

.copy_icon:hover {
  filter: drop-shadow(0 0 1px #ff802a) drop-shadow(0 0 7px #ff802a);
}

@keyframes AnimatedText {
  0% {
    background-position: 0% 51%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 51%
  }
}

.gradient-text {
  font-size: 21px;
  background: linear-gradient(315deg, #B82E1F 0%, #F2C17D 74%);
  background-size: 300% 300%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: AnimatedText 3s ease infinite;
  -moz-animation: AnimatedText 3s ease infinite;
  -o-animation: AnimatedText 3s ease infinite;
  animation: AnimatedText 3s ease infinite;
  font-size: 1.1em;
  font-weight: bold;
}

@media only screen and (max-width : 1200px) {
  .page-wrapper {
    width: 80vw;
  }

  .inv-guide-img-first {
    width: 40vw;
  }

  .inv-guide-img {
    width: 35vw;
  }
}

@media only screen and (max-width : 1000px) {
  .page-wrapper {
    padding-bottom: 10vh;
  }

  .footer {
    display: none;
  }
}
</style>

<template>
  <div id="app" :class="theme">
    <div class="scrollbar" id="style-default">
      <div class="force-overflow"></div>
    </div>

    <HeaderComponent />
    <router-view />

  </div>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue'

export default {
  name: 'app',
  components: {
    HeaderComponent
  },
  computed: {
    theme() {
      return this.$store.state.theme
    }
  },
  created() {
    if (this.$route.path.slice(this.$route.path.lastIndexOf('/') + 1) === 'eng' ||
      this.$route.path.slice(this.$route.path.lastIndexOf('/') + 1) === 'ru' ||
      this.$route.path.slice(this.$route.path.lastIndexOf('/') + 1) === 'ukr')
      this.$store.state.lang = this.$route.path.slice(this.$route.path.lastIndexOf('/') + 1)
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Karla|Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

#app {
  margin: 0;
  padding: 0;
  min-width: 350px;
  font-family: 'Karla', 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  min-height: 100vh;
}

/* Dark Blue/Grey Scheme */
.darkBlue_gray_theme {
  color: #fff;
  background-color: #efefef;
}

.darkBlue_gray_theme .header-wrap {
  color: #fff;
  background-color: black;
}

.darkBlue_gray_theme .page-wrapper {
  background: url(./assets/img/bg1s.png) no-repeat center 0;
}

.darkBlue_gray_theme #guide-container {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0 3vw;
  color: #fff;
}

.darkBlue_gray_theme #free_skins {
  background: url(./assets/img/bg1s.png) no-repeat center 0;
}

.darkBlue_gray_theme #news-container {
  background: url(./assets/img/bg1s.png) no-repeat center 0;
  background-color: #efefef;
  min-height: calc(100vh - 3em - 5vw - 2px) !important;
}

.darkBlue_gray_theme .news-block {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0 3vw;
  padding-top: 3vw;
}

.darkBlue_gray_theme #free_skins-content {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0 3vw;
  color: #fff;
}

.darkBlue_gray_theme .guide-black {
  color: #000;
}

@media only screen and (max-width : 450px) {
  .darkBlue_gray_theme .black_for_mobile {
    color: black;
  }
}

.darkBlue_gray_theme #contract-with-arrow {
  background-color: rgba(255, 255, 255, 0.07);
  padding: 2vh 3vw;
}

.darkBlue_gray_theme #settings-wrapper {
  background: url(./assets/img/bg1s.png) no-repeat center 0;
}

.darkBlue_gray_theme #setting-content {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 2vh 3vw;
}

.darkBlue_gray_theme #about-container {
  background: url(./assets/img/bg1s.png) no-repeat center 0;
}

.darkBlue_gray_theme #about {
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 2vh 3vw;
}

.darkBlue_gray_theme #main-align-wrapper {
  background: url(./assets/img/bg1s.png) no-repeat center 0;
}

.darkBlue_gray_theme #settings_img {
  fill: #fff;
}

.darkBlue_gray_theme .settings_checkbox[checked="checked"] {
  background-color: #d774a0;
}

.darkBlue_gray_theme .container {
  color: #fff;
}

.darkBlue_gray_theme .settings-block {
  background-color: rgba(0, 0, 0, .1);
}

.darkBlue_gray_theme #arrow_right {
  fill: #fff;
}

::-webkit-scrollbar-track {
  background-color: #efefef;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #efefef;
}

::-webkit-scrollbar-thumb {
  background-color: #000;
}

/* Khaki/Black Scheme */
.khaki_black_theme {
  color: #ffffff;
  background-color: #18181a;
}

.khaki_black_theme h1 {
  color: #decd93;
}

.khaki_black_theme h2 {
  color: #decd93;
}

.khaki_black_theme h3 {
  color: #decd93;
}

.khaki_black_theme h4 {
  color: #decd93;
}

.khaki_black_theme .news_a {
  color: #ffffff !important;
}

.khaki_black_theme .header-wrap {
  color: #fff;
  background-color: #26282a;
}

.khaki_black_theme #settings_img {
  fill: #decd93;
}

.khaki_black_theme .settings_checkbox[checked="checked"] {
  background-color: #b8aa7b;
}

.khaki_black_theme .settings-block {
  background-color: #26282a;
}

.khaki_black_theme .container {
  color: #ffffff;
  /* color: #b8aa7b; */
}

.khaki_black_theme #arrow_right {
  fill: #ffffff;
  /* fill: #b8aa7b; */
}

/* Khaki/Black Scheme */
.purple_gray_theme {
  color: #423c46;
  background-color: #dad6d9;
}

.purple_gray_theme .news_a {
  color: #423c46 !important;
}

.purple_gray_theme .header-wrap {
  color: #fff;
  background-color: #312c34;
}

.purple_gray_theme #settings_img {
  fill: #423c46;
}

.purple_gray_theme .settings_checkbox[checked="checked"] {
  background-color: #423c46;
}

.purple_gray_theme .settings-block {
  background-color: rgba(0, 0, 0, .05);
}

.purple_gray_theme #news-container {
  background-color: rgba(0, 0, 0, .05);
}

.purple_gray_theme .container {
  color: #423c46;
}

.purple_gray_theme #arrow_right {
  fill: #423c46;
}

.purple_gray_theme #footer-row-first {
  color: #fff;
}


.fadeit {
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.grid {
  display: grid;
}

@media only screen and (max-width : 600px) {
  #main_page {
    height: 100%;
  }
}

@media only screen and (max-height : 500px) {
  #main_page {
    height: 100%;
  }
}</style>

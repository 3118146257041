<template>
    <div data-aos="fade-in" id="not_found">
        <div id="content1">
            <h2>404 - PAGE NOT FOUND</h2>
            <a href="/">... go to start page</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
}
</script>

<style scoped>
#not_found {
    height: calc(100vh - 3em);
    display: flex;
    align-items: center;
    justify-content: center;
}

h2 {
    font-size: 5em;
    color: #000;
}

a {
    color: #000;
    font-size: 1em;
}
</style>

import Vue from 'vue'
import Router from 'vue-router'
import MainPage from '@/components/MainPage'
import GuideComponent from '@/components/GuideComponent'
import AboutUs from '@/components/AboutUs'
import NotFound from '@/components/NotFound'
import FreeSkins from '@/components/FreeSkins'
// import D3SkinViewer from '@/components/D3SkinViewer'
import NewsComponent from '@/components/NewsComponent'
import CS20Price from '@/components/blog/CS20Price'
import Update18_20 from '@/components/blog/Update18_20'
// import InvestCalculator from '@/components/InvestCalculator'
import InvestmentGuide from '@/components/InvestmentGuide'
import AimTrainingMaps from '@/components/AimTrainingMaps'
import CSGORanks from '@/components/CSGORanks'
// import CaseOpener from '@/components/CaseOpener/CaseOpener.vue'
import CsgoRoll from '@/components/referral/CsgoRoll.vue'
// import Aim from '@/components/AimComponent'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'mainPage',
      component: MainPage,
      meta: {
        title: 'Trade Academy - Profitable CS:GO trade ups simulator / calculator.',
        metaTags: [
          {
            description: 'CS:GO trade up contract profit simulator / calculator. How to make a profit from CS:GO Trade Ups. You will see many great examples of CS:GO Trade Ups.'
          },
          {
            keywords: 'CSGO trade up contracts, trade up simulator, profitable trade ups, trade ups, trade up calculator'
          }
        ]
      }
    },
    {
      path: '/ru',
      name: 'mainPage',
      component: MainPage,
      meta: {
        title: 'Trade Academy - Profitable CS:GO trade ups simulator / calculator.',
        metaTags: [
          {
            description: 'CS:GO trade up contract profit simulator / calculator. How to make a profit from CS:GO Trade Ups. You will see many great examples of CS:GO Trade Ups.'
          },
          {
            keywords: 'CSGO trade up contracts, trade up simulator, profitable trade ups, trade ups, trade up calculator'
          }
        ]
      }
    },
    {
      path: '/ukr',
      name: 'mainPage',
      component: MainPage,
      meta: {
        title: 'Trade Academy - Profitable CS:GO trade ups simulator / calculator.',
        metaTags: [
          {
            description: 'CS:GO trade up contract profit simulator / calculator. How to make a profit from CS:GO Trade Ups. You will see many great examples of CS:GO Trade Ups.'
          },
          {
            keywords: 'CSGO trade up contracts, trade up simulator, profitable trade ups, trade ups, trade up calculator'
          }
        ]
      }
    },
    {
      path: '/guide/',
      name: 'guidePage',
      component: GuideComponent,
      meta: {
        title: 'How CS:GO Trade Up Contracts Works. Interesting and simple guide.',
        metaTags: [
          {
            description: 'Check out our simple guide for CS:GO Trade Ups. After reading this guide step by step, you will completely understand how CS:GO Trade Ups work.'
          },
          {
            keywords: 'CSGO trade up contracts, trade up guide, how trade ups works, trade ups, how to make trade ups'
          }
        ]
      }
    },
    {
      path: '/guide/eng',
      name: 'guidePage',
      component: GuideComponent,
      meta: {
        title: 'How CS:GO Trade Up Contracts Works. Interesting and simple guide',
        metaTags: [
          {
            description: 'Check out our simple guide for CS:GO Trade Ups. After reading this guide step by step, you will completely understand how CS:GO Trade Ups work.'
          },
          {
            keywords: 'CSGO trade up contracts, trade up guide, how trade ups works, trade ups, how to make trade ups'
          }
        ]
      }
    },
    {
      path: '/guide/ru',
      name: 'guidePage',
      component: GuideComponent,
      meta: {
        title: 'Как работают CS:GO контракты обмена. Интересное и простое руководство',
        metaTags: [
          {
            description: 'Ознакомьтесь с нашим простым руководством по контрактам в CS:GO. Прочитав это руководство шаг за шагом, вы полностью поймете, как работают CS:GO контракты.'
          },
          {
            keywords: 'CSGO контракты, гайд по контрактам ксго, как работают контракты'
          }
        ]
      }
    },
    {
      path: '/guide/ukr',
      name: 'guidePage',
      component: GuideComponent,
      meta: {
        title: 'Як працюють CS:GO контракти обміну. Цікава і проста стаття',
        metaTags: [
          {
            description: 'Ознайомтеся з нашим простим керівництвом по контрактах в CS:GO. Прочитавши це керівництво крок за кроком, ви повністю зрозумієте, як працюют CS:GO контаркти.'
          },
          {
            keywords: 'CSGO контракти, гайд по контрактам ксго, як працють контракти'
          }
        ]
      }
    },
    {
      path: '/about/',
      name: 'aboutPage',
      component: AboutUs
    },
    {
      path: '/free_skins/',
      name: 'FreeSkinsPage',
      component: FreeSkins,
      meta: {
        title: 'CS:GO & DOTA2 & VGO Free Affilate Codes. Free CS:GO skins.',
        metaTags: [
          {
            description: 'If you want free skins, you can check out our bonus codes. We provide big list of best CS:GO, DOTA2 sites 2020 with bonus codes. Win free skins.'
          },
          {
            keywords: 'CSGO free skins, referal codes, afilate codes, csgo free skins, dota2 free skins, vgo free skins'
          }
        ]
      }
    },
    // {
    //   path: '/skin_viewer/',
    //   name: 'D3SkinViewer',
    //   component: D3SkinViewer,
    //   meta: {
    //     title: 'CS:GO 3D Skin Viewer. Make your own CS:GO skin from photo.',
    //     metaTags: [
    //       {
    //         description: 'If you want preview CS:GO skins or even make your new one, check out our brand new tool - 3D Skin Viewer.'
    //       },
    //       {
    //         keywords: 'CSGO skin viewer, 3D CSGO skins, how to make skin, awp skins, ak-47 skins'
    //       }
    //     ]
    //   }
    // },
    {
      path: '/about/:lang',
      name: 'aboutPage',
      component: AboutUs
    },
    {
      path: '/news/',
      name: 'newsPage',
      component: NewsComponent,
      meta: {
        title: 'Check Out Most Important CS:GO News.',
        metaTags: [
          {
            description: 'We provide trusted CSGO news. Check out latest CSGO, cybersport, skins, marketplace news. You can also propose news that you think are important.'
          },
          {
            keywords: 'CSGO news, cybersport news, pro players, csgo updates'
          }
        ]
      }
    },
    {
      path: '/news/:lang',
      name: 'newsPage',
      component: NewsComponent,
      meta: {
        title: 'Check Out Most Important CS:GO News.',
        metaTags: [
          {
            description: 'We provide trusted CSGO news. Check out latest CSGO, cybersport, skins, marketplace news. You can also propose news that you think are important.'
          },
          {
            keywords: 'CSGO news, cybersport news, pro players, csgo updates'
          }
        ]
      }
    },
    {
      path: '/update_10_18/',
      name: 'update_10_18',
      component: Update18_20,
      meta: {
        title: 'CSGO update from 18.10. CS20 Case + CS20 Sticker Capsule',
        metaTags: [
          {
            description: 'What new was added on CSGO update from 18.10. CS20 Case and CS20 Sticker Capsule items showcase.'
          },
          {
            keywords: 'CSGO update, 18.10, CS20 Case, CS20 Sticker Capsule, showcase'
          }
        ]
      }
    },
    {
      path: '/update_10_18/:lang',
      name: 'update_10_18',
      component: Update18_20,
      meta: {
        title: 'CSGO update from 18.10. CS20 Case + CS20 Sticker Capsule',
        metaTags: [
          {
            description: 'What new was added on CSGO update from 18.10. CS20 Case and CS20 Sticker Capsule items showcase.'
          },
          {
            keywords: 'CSGO update, 18.10, CS20 Case, CS20 Sticker Capsule, showcase'
          }
        ]
      }
    },
    {
      path: '/cs20_case_price/',
      name: 'cs20_case_price',
      component: CS20Price,
      meta: {
        title: 'Why CS20 Case Price is so low?',
        metaTags: [
          {
            description: 'In this topic we will think about CS20 Case price.'
          },
          {
            keywords: 'CSGO, news, CS20, case price'
          }
        ]
      }
    },
    {
      path: '/cs20_case_price/:lang',
      name: 'cs20_case_price',
      component: CS20Price,
      meta: {
        title: 'Why CS20 Case Price is so low?',
        metaTags: [
          {
            description: 'In this topic we will think about CS20 Case price.'
          },
          {
            keywords: 'CSGO news, CS20, case price'
          }
        ]
      }
    },
    // {
    //   path: '/invest-calculator',
    //   name: 'invest_calculator',
    //   component: InvestCalculator,
    //   meta: {
    //     title: 'CS:GO Investment Profit Calculator from TAC. Live Prices',
    //     metaTags: [
    //       {
    //         description: 'Our tool will help you with CS:GO Steam Investments tracking. Easy profit tracker / calculator. Prices change every day. Make your investing road easier.'
    //       },
    //       {
    //         keywords: 'Steam, investments, calculator, profit, skins, CSGO items'
    //       }
    //     ]
    //   }
    // },
    {
      path: '/invest-calculator/guide',
      name: 'invest_calculator_guide',
      component: InvestmentGuide,
      meta: {
        title: 'Introducing to CS:GO Investments. Guide. The best Steam Investments.',
        metaTags: [
          {
            description: 'How to make profit on CS:GO investments? Best tactics and toold. The best CS:GO investments of 2020.'
          },
          {
            keywords: 'Steam, investments, calculator, profit, skins, CSGO items'
          }
        ]
      }
    },
    {
      path: '/best-csgo-training-maps',
      name: 'csgo_training_maps',
      component: AimTrainingMaps,
      meta: {
        title: 'Best CS:GO Training Maps. Improve your skill - TAC.',
        metaTags: [
          {
            description: 'This guide gives you the best CS:GO Training Maps to help you improve your CS:GO skills in the following aspects: aiming, spray control, peeks, defense, smokes, flashes and others.'
          },
          {
            keywords: 'CSGO, skill, aim, smokes, flashes, peek'
          }
        ]
      }
    },
    {
      path: '/csgo-ranks',
      name: 'csgo_ranks',
      component: CSGORanks,
      meta: {
        title: 'CS:GO Ranking System. How to Rank Up. Ranks List.',
        metaTags: [
          {
            description: 'The CS:GO ranking system is very important part of a Game, because this system helps a player to understand how good he is. Allows CS:GO players to compete with equal-skilled opponents in a Match Making. Gives an incentive to get better in the game and reach the highest ranks. This system has certain features, which we will discuss in this guide.'
          },
          {
            keywords: 'CSGO, ranks, rank up, ranking system, rank list'
          }
        ]
      }
    },
    // {
    //   path: '/case-opener',
    //   name: 'csgo_ranks',
    //   component: CaseOpener,
    //   meta: {
    //     title: 'CS:GO Ranking System. How to Rank Up. Ranks List.',
    //     metaTags: [
    //       {
    //         description: 'The CS:GO ranking system is very important part of a Game, because this system helps a player to understand how good he is. Allows CS:GO players to compete with equal-skilled opponents in a Match Making. Gives an incentive to get better in the game and reach the highest ranks. This system has certain features, which we will discuss in this guide.'
    //       },
    //       {
    //         keywords: 'CSGO, ranks, rank up, ranking system, rank list'
    //       }
    //     ]
    //   }
    // },
    {
      path: '/csgo-roll-code',
      name: 'csgo-roll',
      component: CsgoRoll,
      meta: {
        title: 'CSGORoll Free Promo Codes. 3 Free Cases + bonus.',
        metaTags: [
          {
            description: 'Get your free CSGO Roll promo/referral codes. After successful using it you will get 3 free cases and a chance to win expensive skins'
          },
          {
            keywords: 'CSGORoll, CSGO Roll, affilate codes, referral codes, CSGO, cs'
          }
        ]
      }
    },
    // {
    //   path: '/aim',
    //   name: 'aim',
    //   component: Aim,
    //   meta: {
    //     title: 'Introducing to Aim.',
    //     metaTags: [
    //       {
    //         description: 'How to make profit on CSGO investments? Best tactics and toold. The best CSGO investments of 2020.'
    //       },
    //       {
    //         keywords: 'Steam, investments, calculator, profit, skins, CSGO items'
    //       }
    //     ]
    //   }
    // },
    {
      path: '/404',
      name: '404',
      component: NotFound,
    },
    {
      path: '*',
      redirect: '/404'
    }
  ]
})
<template>
    <div id="free_skins_main_wrapper">
        <h1>CSGO Free Skins and Referral Codes 2021</h1>
        <div class="f_s_header">Here you can find free affilate codes which can be used on csgo skins powered sites. Some
            sites gives really good proporsals,
            like free coins, cases or skins. Many people wins really cool skins from those codes. Then you can use those
            skins for investments or trade
            ups. Best of luck for you.
        </div>
        <div data-aos="fade-in" id="free_skins">
            <div id="free_skins-content">
                <div data-aos="fade-up" data-aos-delay="100" class="free_skins_block best_aff">
                    <div class="free_skins-icon free_skins-img-wrapper">
                        <a href="https://dmarket.com?ref=FAC5LymqtT" target="blank">
                            <img class="img_filter_free" src="../assets/img/dmarket-dmt-logo.svg" height="40"
                                alt="atse code" id="atse">
                        </a>
                    </div>
                    <div class="free_skins-prof c_red">Best Skins Buy/Sell site</div>
                    <div class="dmarket_link_div"><a href="https://dmarket.com?ref=FAC5LymqtT" target="blank"
                            class="dmarket_link">Dmarket Link</a></div>
                            <div>Lowest commission for selling skins - 2%</div>
                </div>
                <div data-aos="fade-up" data-aos-delay="200" class="free_skins_block free_skins_black best_aff">
                    <div class="free_skins-icon free_skins-img-wrapper">
                        <a href="https://csgoroll.com/r/tradeacademy" target="blank">
                            <img height="35px" class="img_filter_free" src="../assets/img/csgoroll.png" alt="atse code"
                                id="atse">
                        </a>
                    </div>
                    <div class="free_skins-prof"><span class="c_red">CSGORoll Free Code<br>3 free cases</span></div>
                    <div class="dmarket_link_div" style="padding-top: 3px"><a href="https://csgoroll.com/r/TAC"
                            target="blank" class="dmarket_link">CSGO Roll Link</a></div>
                </div>

                <div data-aos="fade-up" data-aos-delay="300" class="free_skins_block free_skins_black best_aff">
                    <div class="free_skins-icon">
                        <a href="https://csgo500.com/rewards" target="blank">
                            <img class="img_filter_free" src="../assets/img/csgo500.png" alt="csgo500 code" id="csgo500">
                        </a>
                    </div>
                    <div class="free_skins-prof c_red">CSGO500 Free Code<br>$1</div>
                    <div class="free_skins-code c_red">tradeacademy</div>
                </div>

                <!-- <div data-aos="fade-up" data-aos-delay="400" class="free_skins_block best_aff">
                    <div class="free_skins-name"></div>
                    <div class="free_skins-icon">
                        <a href="https://www.thecryptoprophecies.com/?mwr=b0bf68f3" target="blank">
                            <img class="img_filter_free" src="../assets/img/airdrop1.png" alt="code" id="csgo500">
                        </a>
                    </div>
                    <div class="free_skins-prof"><span class="c_red">bTCP AirDrop 5$</span></div>
                    <div class="dmarket_link_div"><a href="https://www.thecryptoprophecies.com/?mwr=b0bf68f3" target="blank"
                            class="dmarket_link">AirDrop Link</a></div>
                </div> -->

                <div data-aos="fade-up" data-aos-delay="500" class="free_skins_block free_skins_black best_aff">
                    <div class="free_skins-icon free_skins-img-wrapper">
                        <a href="https://plg.bet/?r=TACUP" target="blank">
                            <img class="img_filter_free" src="../assets/img/csgopolygon.png" alt="csgopolygon code"
                                id="csgopolygon">
                        </a>
                    </div>
                    <div class="free_skins-prof"><span class="c_red">Free $1.00</span></div>
                    <div class="free_skins-code" style="color: #fff; padding-top: 15px;">TACUP</div>
                </div>

                <div data-aos="fade-up" data-aos-delay="600" class="free_skins_block best_aff">
                    <div class="free_skins-icon free_skins-img-wrapper">
                        <a href="https://csgoempire.com/r/TRADEUPA" target="blank">
                            <img class="img_filter_free" src="../assets/img/csgoempirelogo.png" alt="csgo empire code"
                                height="30" width="auto">
                        </a>
                    </div>
                    <div class="free_skins-prof">Free CS:GO Case</div>
                    <div class="dmarket_link_div"><a href="https://csgoempire.com/r/TRADEUPA" target="blank"
                            class="dmarket_link">CSGO Empire Link</a></div>
                </div>

                <!-- <div data-aos="fade-up" data-aos-delay="700" class="free_skins_block best_aff">
                    <div class="free_skins-icon free_skins-img-wrapper">
                        <a href="https://www.wtfskins.com/" target="blank">
                            <img class="img_filter_free" src="../assets/img/xmass_logo.png" alt="WTF Skins code"
                                id="gocase">
                        </a>
                    </div>
                    <div class="free_skins-prof"><span class="c_red">Free 0.25$</span></div>
                    <div class="free_skins-code">TACUP</div>
                </div> -->

                <!-- <div data-aos="fade-up" data-aos-delay="800" class="free_skins_block best_aff">
                    <div class="free_skins-name"></div>
                    <div class="free_skins-icon free_skins-img-wrapper">
                        <a href="https://www.binance.com/en/register?ref=CR4Y7O6S" target="blank">
                            <img height="90px" src="../assets/img/binance.svg" alt="duelbits code" id="gocase">
                        </a>
                    </div>
                    <div class="free_skins-prof"><span class="c_red">10% commision reduce</span></div>
                    <div style="padding-top: 3px" class="dmarket_link_div"><a
                            href="https://www.binance.com/en/register?ref=CR4Y7O6S" target="blank"
                            class="dmarket_link">Binance Link</a></div>
                </div> -->

                <div data-aos="fade-up" data-aos-delay="900" class="free_skins_block best_aff">
                    <div class="free_skins-name"></div>
                    <div class="free_skins-icon free_skins-img-wrapper">
                        <a href="https://tradeit.gg/?aff=tac" target="blank">
                            <img height="55px" src="../assets/img/tradeit.svg" alt="tradeit code" id="gocase">
                        </a>
                    </div>
                    <div class="free_skins-prof"><span class="c_red">Trade CSGO Skins</span></div>
                    <div style="padding-top: 3px" class="dmarket_link_div"><a href="https://tradeit.gg/?aff=tac"
                            target="blank" class="dmarket_link">TRADEIT.GG Link</a></div>
                </div>
                <div data-aos="fade-up" data-aos-delay="1000" class="free_skins_block best_aff">
                    <div class="free_skins-name"></div>
                    <div class="free_skins-icon free_skins-img-wrapper">
                        <a href="https://swap.gg/?r=wAvCuOMWLC" target="blank">
                            <img height="25px" src="../assets/img/swapgg.png" alt="tradeit code" id="gocase">
                        </a>
                    </div>
                    <div class="free_skins-prof"><span class="c_red">Trade CSGO Skins</span></div>
                    <div style="padding-top: 3px" class="dmarket_link_div"><a href="https://swap.gg/?r=wAvCuOMWLC"
                            target="blank" class="dmarket_link">SWAP.GG Link</a></div>
                </div>

                <div data-aos="fade-up" data-aos-delay="1100" class="free_skins_block best_aff">
                    <div class="free_skins-icon free_skins-img-wrapper">
                        <a href="https://bitskins.com/?ref_alias=tradeup" target="blank">
                            <img class="img_filter_free" src="../assets/img/bitskins-logo.svg" height="25" width="auto"
                                alt="atse code">
                        </a>
                    </div>
                    <div class="free_skins-prof c_red">Best Skins Buy/Sell site</div>
                    <div class="dmarket_link_div"><a href="https://bitskins.com/?ref_alias=tradeup" target="blank"
                            class="dmarket_link">Bitskins Link</a></div>
                </div>

            </div>

        </div>
        <div class="codes_introduction">
            <h2>What is CSGO Affilate Codes?</h2>
            <p>
                CSGO Free/Referral Codes are not more than little bonus from one CSGO Betting/Gambling Project.
                Referral codes are either single or multiple. For example, a one-time code can be a Free CSGO Case from a
                certain resource or bonus money on your balance. A reusable code can be a permanent bonus to replenish the
                balance.
            </p>
            <h2>
                Why shoud I use those codes?
            </h2>
            <p>
                You can use these codes to get free skins or just for good bonus from site. I have heard many times from my
                referrals that they raised skins worth more than $100 from single referral code. This can be a very good
                start for the collector of CSGO skins.
            </p>
            <h2>
                How to use the referral code on the site?
            </h2>
            <p>
                Depending on the method of use, free codes are either embedded in the link or should be entered directly on
                the site. They are usually built into a direct link to the resource to save you time.
                So all the sites on this page are completely legitimate and are used by millions of users around the world.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FreeSkins'
}
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
}

h1 {
    margin-top: 3vh;
}

img {
    max-height: 80px;
}

#free_skins_main_wrapper {
    min-height: calc(100vh - 3em - 20px);
}

.dmarket_link_div {
    padding-top: 15px;
}

.dmarket_link {
    text-decoration: none;
    color: #ffffff
}

.dmarket_link:hover {
    text-decoration: underline;
}

.f_s_header {
    margin: 0 auto;
    margin-top: 20px;
    width: 70%;
    font-size: 19px;
}

#free_skins {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

#free_skins-content {
    width: 50vw;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 2em;
    padding: 2em;
    font-size: 1.2em;
}

.free_skins_block {
    border: 1px solid gray;
}

.free_skins-prof {
    padding: 0.2em 0;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
}

.free_skins-code {
    padding: 0.2em 0;
}

.free_skins-img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
}

.free_skins_black {
    color: #000;
}

#csgofast {
    padding-top: 4px;
}

#csgo500 {
    width: 77px;
}

#atse {
    width: 80px;
}

.best_aff {
    border: 2px solid #ffffff;
}

.c_red {
    padding-left: 8px;
    padding-right: 8px;
    color: #ffffff;
}

.img_filter_free {
    transition: 0.25s ease-out;
}

.img_filter_free:hover {
    filter: drop-shadow(0px 0px 3px #4444dd) drop-shadow(0px 0px 10px #4444dd);
}

h2 {
    margin-top: 20px;
}

p {
    margin: 0 auto;
    max-width: 70vw;
    font-size: 18px;
}

.codes_introduction {
    padding-bottom: 50px;
}

@media only screen and (max-width : 900px) {
    #free_skins-content {
        width: 70vw;
        grid-template-columns: repeat(2, 1fr);
        font-size: 1em;
    }
}</style>

<template>
  <div data-aos="fade-in" class="page-wrapper">
    <h1>{{ content[0] }}</h1>

    <div data-aos="fade-up" class="container" id="guide-container">

      <h2 class="title" id="title1">{{ content[1] }}
        <hr>
      </h2>
      <div id="quote">{{ content[2] }}<br>
        <strong id="valve">{{ content[3] }}</strong>
      </div>
      <p>{{ content[4] }}</p>

      <InArticleAdsense data-ad-client="ca-pub-8686792985613246" data-ad-slot="4743559288">
      </InArticleAdsense>

      <h2 class="title">{{ content[5] }}
        <hr>
      </h2>
      <p id="how_works">{{ content[6] }}<strong>{{ content[7] }}</strong>{{ content[8] }}<strong>{{ content[9] }}</strong>
        {{ content[10] }}<strong>{{ content[11] }}</strong>{{ content[12] }}
      </p>
      <img id="float-ranges"
        src="https://steamuserimages-a.akamaihd.net/ugc/398930471911722931/466827CFE2B4D3739264611173C3D1EFAB540ACF/"
        alt="float ranges">
      <p class="black_for_mobile">{{ content[13] }}<strong>{{ content[14] }}</strong>{{ content[15] }}<strong>{{
        content[16] }}</strong>
        {{ content[17] }}<strong>{{ content[18] }}</strong>{{ content[19] }}<strong>{{ content[20] }}</strong>
        {{ content[21] }}<strong>{{ content[22] }}</strong>{{ content[23] }}<strong>{{ content[24] }}</strong>
        {{ content[25] }}<strong>{{ content[26] }}</strong>{{ content[27] }}<strong>{{ content[28] }}</strong>{{
          content[29] }}</p>
      <div class="black_for_mobile" id="about-formula">
        <p data-aos="zoom-in">{{ content[30] }}</p>
        <p data-aos="zoom-in" id="main-formula">{{ content[31] }}</p>
        <p data-aos="zoom-in">{{ content[32] }}<br>
          {{ content[33] }}<br>
          {{ content[34] }}</p>
      </div>

      <InArticleAdsense data-ad-client="ca-pub-8686792985613246" data-ad-slot="2651107789">
      </InArticleAdsense>

      <div class="guide-black">
        <h2 data-aos="zoom-in" class="title">{{ content[35] }}
          <hr>
        </h2>
        <div id="what_u_need">
          <p data-aos="zoom-in">{{ content[36] }}<br></p>
          <img data-aos="zoom-in" src="@/assets/img/csgo4.webp" alt="csgo prime">
          <p data-aos="zoom-in">{{ content[37] }}<br></p>
          <p data-aos="zoom-in">{{ content[38] }}<br></p>
          <p data-aos="zoom-in">{{ content[39] }}<br></p>
          <img data-aos="zoom-in" width="250px" src="@/assets/img/csgo1.webp" alt="10 csgo skins">
          <p data-aos="zoom-in">{{ content[40] }}</p>
          <img data-aos="zoom-in" width="250px" src="@/assets/img/csgo2.webp" alt="10 csgo skins">
          <p data-aos="zoom-in">{{ content[41] }}</p>
          <img id="last-img" data-aos="zoom-in" width="400px" src="@/assets/img/csgo3.webp" alt="10 csgo skins">
        </div>
      </div>
    </div>
    <FooterComponent class="footer" />
  </div>
</template>

<script>
import FooterComponent from '../components/FooterComponent.vue'

export default {
  name: 'GuideComponent',
  components: {
    FooterComponent
  },
  computed: {
    content() {
      return this.$store.state.content[this.$store.state.lang].guide
    }
  }
}
</script>

<style scoped>
h1 {
  text-align: center;
}

h1,
h2 {
  line-height: 1.2em;
  font-size: 1.5rem;
}

img {
  display: block;
  margin: 1em auto;
}

hr {
  border: 0;
  height: 1px;
  background: #333;
  width: inherit;
  margin-top: 0.5em;
}

.page-wrapper {
  display: grid;
  text-align: justify;
  font-size: 1.2rem;
  line-height: 1.2em;
}

.container {
  width: 50vw;
  justify-self: center;
}

.title {
  margin-top: 3.5em;
  font-size: 1.4rem;
}

#title1 {
  margin-top: 2em;
}

h1 {
  font-size: 2rem;
  margin-top: 0.8em;
  margin-bottom: 1.5em;
}

#main-formula {
  font-size: 1.2em;
  font-weight: bold;
}

#quote {
  width: 48vw;
  margin: 0 auto;
  padding-top: 1em;
  font-size: 1.2rem;
  font-style: italic;
  margin-bottom: 3em;
}

#valve {
  font-size: 1.2em;
  float: right;
}

#how_works {
  padding-top: 1em;
}

#float-ranges {
  display: block;
  margin: 0 auto;
  width: 70%;
  margin-top: 1em;
  margin-bottom: 1em;
}

#about-formula {
  margin-top: 1em;
}

#main-formula {
  line-height: 2em;
}

#what_u_need {
  margin-top: 1em;
}

#last-img {
  padding-bottom: 1em;
}

@media only screen and (max-width : 1000px) {
  .footer {
    display: none;
  }
}

@media only screen and (max-width : 850px) {
  .container {
    width: 70vw;
  }

  img {
    width: 300px
  }
}</style>

<template>
    <div class="input grid">
        <img id="input-img" v-bind:src="icon_url10" :alt="name10">
        <div id="input-info">
            <div class="main-inf">
                <span @click="copyToClipboard(name10)"><img src="../assets/img/copy-7ec847ec.svg" alt="copy"></span>
                <p id="input-type">{{ name10.substring(0, name10.indexOf('|') - 1) }}</p>
                <p id="input-name">{{ name10.substring(name10.indexOf('|') + 2, name10.indexOf('(') - 1) }}</p>
                <p id="input-ex" v-bind:style="{ color: setExtColor }">{{ name10.substring(name10.indexOf('(') + 1,
            name10.length - 1) }}</p>
            </div>

            <div class="add-inf">
                <p id="input-float">Average Float: <span class="max_input_float">{{ (this.max_input_float || 0
        ).toFixed(4) }}</span></p>
                <p id="input-price">Steam price: <span>{{ price10 }}$</span></p>
                <div class="buy_links_wrapper">
                    <a target="_blank" :href="`https://steamcommunity.com/market/listings/730/${this.name10}`">
                        <img width="25px" class="steam" src="@/assets/img/Steam_icon_logo.svg" alt="steam"
                            style="cursor:pointer;">
                    </a>
                    <a class="bitskins_img" target="_blank"
          :href="`https://bitskins.com/market/cs2?search={%22where%22:{%22skin_name%22:%22${this.name10}%22}}&sort_by=price&ref_alias=tradeup`">
                        <img width="25px" src="@/assets/img/bitskins-icon.png" alt="biskins" style="cursor:pointer;">
                    </a>
        <a target="_blank" :href="`https://dmarket.com/ua/ingame-items/item-list/csgo-skins?ref=FAC5LymqtT&title=${this.name10}`">
                        <img width="25px" src="@/assets/img/dma.png" alt="dmarket" style="cursor:pointer;">
                    </a>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'InputSkin',
    props: {
        n10: {
            type: String
        },
        p10: {
            type: Number
        },
        i_url10: {
            type: String
        },
        max_input_float: {
            type: Number
        },
    },
    data() {
        return {
            height: 0,
            name10: '',
            price10: 0,
            icon_url10: '',
            color: ''
        };
    },
    created: function () {
        window.addEventListener('scroll', this.calculateHeight);
    },
    destroyed: function () {
        window.removeEventListener('scroll', this.calculateHeight);
    },
    methods: {
        calculateHeight() {
            this.height = window.scrollY
        },
        copyToClipboard(text) {
            const textArea = document.createElement('textarea');
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);
            console.log('Text copied to clipboard');
        },
    },
    computed: {
        setExtColor: function () {
            switch (this.name10.substring(this.name10.indexOf('(') + 1, this.name10.length - 1)) {
                case 'Factory New':
                    return 'lime'
                case 'Minimal Wear':
                    return '#83b200'
                case 'Field-Tested':
                    return 'yellow'
                case 'Well-Worn':
                    return 'orange'
                case 'Battle-Scarred':
                    return 'red'
                default:
                    return 'lime';
            }
        }
    },
    watch: {
        n10: function () {
            this.name10 = this.n10
        },
        p10: function () {
            this.price10 = this.p10
        },
        i_url10: function () {
            this.icon_url10 = this.i_url10
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
    color: white;
}

.input {
    grid-template-columns: 1.2fr 1fr;
    margin-bottom: 10px;
    background-color: rgba(28, 30, 34, 0.8);
    padding-top: 10px;
    padding-bottom: 5px;
    -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
    box-shadow: 0 8px 6px -6px black;
}

#input-info {
    width: 200px;
}

#input-img {
    width: 180px;
    height: 135px;
    object-fit: cover;
    margin-left: 20px;
}

.main-inf {
    position: relative;
    padding: 0 10px 0 10px;
    font-size: 1rem;
}
.main-inf > span {
    cursor: pointer;
    position: absolute;
    right: 10px;
}
.main-inf > span > img {
  height: 20px;
}

.add-inf {
    padding: 5px 0 0 10px;
    font-weight: 600;
}

#input-type {
    font-weight: 600;
    font-size: 1.2rem;
}

#input-ex {
    padding-top: 0.2em;
    font-size: 1rem;
}

img {
    padding: 0;
    margin: 0;
    height: 100%;
}

#steam_icon {
    margin-top: 7px;
    margin-right: 5px;
}

span {
    font-weight: normal;
}

.buy_links_wrapper {
    margin-top: 10px;
    display: flex;
    height: 25px;
    width: max-content;
    grid-gap: 8px;
    margin-bottom: 7px;
}

.bitskins_img {
    transform: scale(1.1);
}

.max_input_float {
    margin-left: 2px;
    background: linear-gradient(315deg, #B82E1F 0%, #F2C17D 74%);
    background-size: 300% 300%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: AnimatedText 3s ease infinite;
    -moz-animation: AnimatedText 3s ease infinite;
    -o-animation: AnimatedText 3s ease infinite;
    animation: AnimatedText 3s ease infinite;
    font-size: 1.1em;
    font-weight: bold;
}

@keyframes AnimatedText {
    0% {
        background-position: 0% 51%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 51%
    }
}

/*==========  Desktop First  ==========*/

@media only screen and (min-width : 3200px) {
    .main-inf {
        font-size: 26px;
    }

    #input-info {
        width: 350px;
    }

    #input-img {
        width: 350px;
        height: calc(350px / 1.33333);
    }

    #input-type {
        font-size: 34px;
    }

    #input-ex {
        font-size: 26px;
    }

    img {
        width: 40px;
    }

    .add-inf {
        font-size: 26px;
    }
}

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1700px) {
    .main-inf {
        font-size: 14px;
    }

    #input-info {
        width: 200px;
    }

    #input-img {
        width: 200px;
        height: calc(200px / 1.33333);
    }

    #input-type {
        font-size: 17px;
    }

    #input-ex {
        font-size: 14px;
    }
}

@media only screen and (max-width : 1500px) {
    .main-inf {
        font-size: 14px;
    }

    #input-info {
        width: 180px;
    }

    #input-img {
        width: 180px;
        height: calc(180px / 1.33333);
    }

    #input-type {
        font-size: 17px;
    }

    #input-ex {
        font-size: 14px;
    }

    .add-inf {
        font-size: 13px;
    }
}

@media only screen and (max-width : 1400px) {
    .main-inf {
        font-size: 13px;
    }

    #input-info {
        width: 170px;
    }

    #input-img {
        width: 170px;
        height: calc(170px / 1.33333);
    }

    #input-type {
        font-size: 16px;
    }

    #input-ex {
        font-size: 13px;
    }

    .add-inf {
        font-size: 12px;
    }
}

@media only screen and (max-width : 1200px) {
    .main-inf {
        font-size: 12px;
    }

    #input-info {
        width: 160px;
    }

    #input-img {
        width: 160px;
        height: calc(160px / 1.33333);
    }

    #input-type {
        font-size: 15px;
    }

    #input-ex {
        font-size: 12px;
    }

    .add-inf {
        font-size: 11px;
    }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
    .main-inf {
        font-size: 10px;
    }

    #input-info {
        width: 130px;
    }

    #input-img {
        width: 130px;
        height: calc(130px / 1.33333);
    }

    #input-type {
        font-size: 13px;
    }

    #input-ex {
        font-size: 9px;
    }

    img {
        width: 17px;
    }

    .add-inf {
        font-size: 9px;
    }
}

@media only screen and (max-height : 900px) {
    .main-inf {
        font-size: 10px;
    }

    #input-info {
        width: 130px;
    }

    #input-img {
        width: 130px;
        height: calc(130px / 1.33333);
    }

    #input-type {
        font-size: 13px;
    }

    #input-ex {
        font-size: 9px;
    }

    img {
        width: 17px;
    }

    .add-inf {
        font-size: 9px;
    }
}
</style>

<template>
  <div class="container grid" id="contract-container">
    <h1 id="h2_info">Random CS:GO Trade Up</h1>
    <p class="put" id="input">Input:</p>
    <div class="input-wrapper grid">
      <InputSkin id="input_skin" :n10="name10" :p10="price10" :i_url10="icon_url10" :max_input_float="max_input_float" />
    </div>

    <p class="put" id="output">Output:</p>
    <div class="output-wrapper">
      <OutputSkin :n1="output1.name1" :p1="output1.price1" :f1="output1.float1" :max_input_f="output1.max_input_float"
        :pr1="output1.percent1" :i_url1="output1.icon_url1" :ch="chance" />
      <OutputSkin :n1="output2.name1" :p1="output2.price1" :f1="output2.float1" :max_input_f="output2.max_input_float"
        :pr1="output2.percent1" :i_url1="output2.icon_url1" :ch="chance" v-show="amount > 1" />
      <OutputSkin :n1="output3.name1" :p1="output3.price1" :f1="output3.float1" :max_input_f="output3.max_input_float"
        :pr1="output3.percent1" :i_url1="output3.icon_url1" :ch="chance" v-show="amount > 2" />
      <OutputSkin :n1="output4.name1" :p1="output4.price1" :f1="output4.float1" :max_input_f="output4.max_input_float"
        :pr1="output4.percent1" :i_url1="output4.icon_url1" :ch="chance" v-show="amount > 3" />
      <OutputSkin :n1="output5.name1" :p1="output5.price1" :f1="output5.float1" :max_input_f="output5.max_input_float"
        :pr1="output5.percent1" :i_url1="output5.icon_url1" :ch="chance" v-show="amount > 4" />
    </div>

    <p id="average-profit">Average profit: <span id="avarage-span">x{{ percent_avg }}</span></p>
    <p>* Prices can be not real time updated. Please, double check skin prices using links below them</p>
  </div>
</template>

<script>
import OutputSkin from './OutputSkin.vue'
import InputSkin from './InputSkin.vue'

export default {
  name: 'ContractComponent',
  components: {
    OutputSkin,
    InputSkin
  },
  props: {
    n10: {
      type: String
    },
    p10: {
      type: Number
    },
    i_url10: {
      type: String
    },
    p_avg: {
      type: Number
    },
    outp: {
      type: Array
    },
    am: {
      type: Number
    },
    load: {
      type: Boolean
    },
    max_input_float: {
      type: Number
    }
  },
  data() {
    return {
      height: 0,
      loading: false,
      name10: '',
      price10: 0,
      icon_url10: '',
      percent_avg: 0,
      output: [],
      amount: 0,
      output1: {},
      output2: {},
      output3: {},
      output4: {},
      output5: {},
      chance: 0
    };
  },
  created() {
    window.addEventListener('scroll', this.calculateHeight);
  },
  destroyed() {
    window.removeEventListener('scroll', this.calculateHeight);
  },
  methods: {
    calculateHeight() {
      this.height = window.scrollY
    }
  },
  watch: {
    load: function () {
      this.loading = this.load
    },
    n10: function () {
      this.name10 = this.n10
    },
    p10: function () {
      this.price10 = this.p10
    },
    i_url10: function () {
      this.icon_url10 = this.i_url10
    },
    p_avg: function () {
      this.percent_avg = this.p_avg.toFixed(2)
    },
    outp: function () {
      this.output = this.outp
      if (this.output.length > 0)
        this.output1 = this.output[0]
      if (this.output.length > 1)
        this.output2 = this.output[1]
      if (this.output.length > 2)
        this.output3 = this.output[2]
      if (this.output.length > 3)
        this.output4 = this.output[3]
      if (this.output.length > 4)
        this.output5 = this.output[4]
    },
    am: function () {
      this.amount = this.am
      this.chance = Math.round(100 / this.am)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  margin: 0;
  padding: 0;
  text-align: left;
}

#h2_info {
  margin-top: 40px;
  font-size: 2em;
  animation-delay: 0.5s;
  text-align: center;
}

img {
  height: auto;
}

#input_skin {
  animation-delay: 0.5s;
}

.input-wrapper {
  justify-self: center;
}

.put {
  margin: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 1.3rem;
}

#input {
  animation-delay: 1s;
}

#output {
  animation-delay: 1s;
}

.output-wrapper {
  animation-delay: 0.5s;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#average-profit {
  animation-delay: 1s;
  margin-top: 5px;
  text-align: center;
  font-weight: bold;
  font-size: 25px;
}

@keyframes AnimatedText {
  0% {
    background-position: 0% 51%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 51%
  }
}

#avarage-span {
  background: linear-gradient(315deg, #B82E1F 0%, #F2C17D 74%);
  background-size: 300% 300%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: AnimatedText 3s ease infinite;
  -moz-animation: AnimatedText 3s ease infinite;
  -o-animation: AnimatedText 3s ease infinite;
  animation: AnimatedText 3s ease infinite;
  font-size: 1.1em;
  font-weight: bold;
}

#adv {
  animation-delay: 1s;
  text-align: center;
  font-size: 16px;
}


@media only screen and (max-width : 1400px) {
  #adv {
    font-size: 14px;
  }

  #average-profit {
    font-size: 22px;
    margin-top: 0px;
  }
}

@media only screen and (min-width : 3200px) {
  .put {
    font-size: 50px;
    margin-bottom: 20px;
  }

  #output {
    margin-top: 20px;
  }

  #average-profit {
    font-size: 55px;
  }

  #adv {
    font-size: 35px;
    margin-bottom: 50px;
  }
}</style>

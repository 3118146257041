<template>
  <Slide disableOutsideClick @closeMenu="handleCloseMenu">
    <a href="/guide"><span class="burger-span">Trade Up Guide</span></a>
    <a href="/"><span class="burger-span">Trade Up Simulator</span></a>
    <a href="/invest-calculator/guide"><span class="burger-span">Investment Guide</span></a>
    <a href="/invest-calculator"><span class="burger-span">Investments Calculator</span></a>
    <a href="/skin_viewer"><span class="burger-span">3D Skin Viewer</span></a>
    <a href="/free_skins"><span class="burger-span">Free Skins</span></a>
    <a href="/news"><span class="burger-span">News</span></a>
    <a href="/about"><span class="burger-span">About</span></a>
  </Slide>
</template>

<script>
import { Slide } from 'vue-burger-menu'

export default {
  name: 'HamburgerComponent',
  components: {
    Slide
  },
  data() {
    return {
      contacts: false,
      about: false,
      how_works: false
    }
  },
  methods: {
    fContacts() {
      this.contacts = !this.contacts
    },
    fAbout() {
      this.about = !this.about
    },
    handleCloseMenu() {
      this.contacts = false
      this.about = false
      this.how_works = false
    },
    cross_clicked() {
      this.how_works = false
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.burger-span {
  color: white !important;
}

.burger-span:hover {
  color: #53c2bf !important;
}

.bm-burger-button {
  width: 18px !important;
  height: 15px !important;
  left: 1.5em !important;
  top: 1.3em !important;
}

.bm-burger-bars {
  background-color: white !important;
}

.line-style {
  height: 2px !important;
}

.bm-item-list {
  margin: 0px !important;
}

.bm-item-list>*>span {
  margin: 0px !important;
}</style>

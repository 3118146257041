<template>
    <div data-aos="fade-in" id="news-container">
        <div class="news-block">

            <a class="news_a" href="/cs20_case_price">
                <div class="news-header first">
                    <h3 class="news-header_h3">{{ content.twenty_seven_ten.header }}</h3>
                    <p class="news-date">27.10.2019</p>
                </div>
            </a>

            <a class="news_a" href="/update_10_18">
                <div class="news-header">
                    <h3 class="news-header_h3">{{ content.twelve_ten.header }}</h3>
                    <p class="news-date"><br>18.10.2019</p>
                </div>
            </a>

            <a class="news_a" href="/best-csgo-training-maps">
                <div class="news-header">
                    <h3 class="news-header_h3">Best CSGO Training Maps</h3>
                    <p class="news-date"><br>04.07.2020</p>
                </div>
            </a>

            <a class="news_a" href="/csgo-ranks">
                <div class="news-header">
                    <h3 class="news-header_h3">CSGO Ranking System Guide</h3>
                    <p class="news-date"><br>05.07.2020</p>
                </div>
            </a>

        </div>
    </div>
</template>

<script>

export default {
    name: 'NewsComponent',
    computed: {
        content() {
            return this.$store.state.content[this.$store.state.lang].news
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
    padding: 0;
    margin: 0;
}

.bold {
    font-weight: bold;
}

#news-container {
    padding-top: 10vh;
    font-size: 1em;
    text-align: start;
}

.news_a {
    text-decoration: none;
    color: #fff;
}

.news-block {
    margin: 0 auto;
    width: 50vw;
    padding-bottom: 100px;
}

.news-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 1.5em;
    padding-bottom: 0.5em;
    border-bottom: 1px solid black;
    font-size: 1.4rem;
    font-weight: bold;
}

.news-header:hover {
    cursor: pointer;
}

.news-header_h3 {
    font-size: 24px;
    width: 60%;
    font-weight: 600;
}

.news-date {
    font-weight: normal;
}

.first {
    padding-top: 0;
}

@media only screen and (max-width : 1000px) {
    .news-block {
        width: 80vw;
    }

    .news-header_h3 {
        font-size: 25px;
        width: 60%;
        font-weight: 600;
    }
}</style>

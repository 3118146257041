<template>
  <div id="main-align-wrapper">
    <div id="contract-with-arrow">
      <loading id="loading" :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage" :loader="dots"
        :background-color="bgc" :color="color" :opacity="opacity"></loading>
      <ContractComponent :style="[isLoading === true ? { 'filter': 'blur(10px)' } : { 'filter': 'blur(0)' }]"
        id="contract " ref="Contract" :n10="contracts.name10" :p10="contracts.price10"
        :max_input_float="contracts.max_input_float10" :i_url10="'https://steamcommunity-a.akamaihd.net/economy/image/' +contracts.icon_url10" :p_avg="contracts.percent_avg"
        :outp="contracts.output" :am="amount" :load="isLoading" />
      <div v-if="isLoading === false" @click="nextContract" class="next_btn">
        <span class="next_btn_text">Next</span>
        <svg :class="{ fadeit: height >= 0 }" id="arrow_right" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="35px" viewBox="0 0 492.004 492.004"
          xml:space="preserve">
          <g>
            <g>
              <path
                d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z" />
            </g>
          </g>
        </svg>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ContractComponent from './ContractComponent.vue'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'MainPage',
  components: {
    ContractComponent,
    Loading
  },
  data() {
    return {
      height: 0,
      contracts: {},
      amount: 0,
      isLoading: false,
      fullPage: true,
      dots: 'dots',
      bgc: '#000000',
      color: '#21c4dd',
      opacity: 0.7
    }
  },
  created: function () {
    window.addEventListener('scroll', this.calculateHeight);
  },
  destroyed: function () {
    window.removeEventListener('scroll', this.calculateHeight);
  },
  mounted() {
    this.contracts = {
        "name10": "MAC-10 | Disco Tech (Field-Tested)",
        "price10": 2.85,
        "icon_url10": "-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpou7umeldf0Ob3fDxBvYyJmoGIhfLLPr7Vn35cppZ32u-Qp92ijgzk-kdqZj_0JdeVcFQ8YlzR-1m-xr_q1JC_vczOnXt9-n512ovruPU",
        "max_input_float10": 0.1786,
        "percent_avg": 1.2722807017543858,
        "output": [
            {
                "name1": "M4A1-S | Player Two (Minimal Wear)",
                "float1": "0.1260",
                "max_input_float": 0.1786,
                "price1": 65.13,
                "percent1": 2.285263157894737,
                "icon_url1": "-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpou-6kejhz2v_Nfz5H_uO1gb-Gw_alIITShWxeupUl0tbN_Iv9nBqxrUA9MWmhINKUIFU9N1rT_AK3lbjnjcLo6ZuazXA1u3R34yzdzha_n1gSORVcLpnc",
                "price10": 57
            },
            {
                "name1": "Glock-18 | Bullet Queen (Field-Tested)",
                "float1": "0.1500",
                "max_input_float": 0.37,
                "price1": 7.39,
                "percent1": 0.2592982456140351,
                "icon_url1": "-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxf0Ob3djFN79fnzL-cluX5MrLVk2Vu5cB1g_zMu9Tzi1Lgr0VoYz3wIoOdIAQ8ZQuC-gS_k73rgcS4ucjJziBmvikm4i7D30vgkBOw7cE",
                "price10": 7.83
            }
        ],
        "success": true
    }
  },
  methods: {
    nextContract() {
      axios.get('https://tradeupacademy.org/new_contract.json')
        // axios.get('http://localhost:3000/new_contract.json')
        .then(response => {
          console.log()
          this.contracts = response.data
        })
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    }
  },
  watch: {
    contracts: function () {
      this.amount = this.contracts.output.length
    },
  }
}
</script>

<style scoped>
* {
  color: #2a3847;
}

#main-align-wrapper {
  display: flex;
  align-items: center;
}

#contract-with-arrow {
  margin: auto;
}

.next_btn {
  margin: 0 auto;
  width: max-content;
  padding: 7px 16px;
  border: 1px solid #ffffff;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.03);
  transition: background 0.25s ease-out;
}

.next_btn:hover {
  background: rgba(255, 255, 255, 0.06);
}

#arrow_right {
  margin-left: 5px;
  transform: scale(0.7);
  transition: transform 0.25s ease-out;
}

.next_btn:hover #arrow_right {
  transform: translateX(10px) scale(0.7);
}

.next_btn_text {
  margin-top: -2px;
  font-size: 28px;
  color: #ffffff;
  font-weight: bold;
}

@media only screen and (max-width : 1380px) {
  #arrow_right {
    width: 30px;
  }

  #main-align-wrapper {
    overflow: visible;
    ;
  }
}
</style>

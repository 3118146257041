<template>
  <div data-aos="fade-in" class="page-wrapper-w-footer">

    <div data-aos="fade-in" class="page-wrapper">

      <h1>CSGO Investment Guide</h1>

      <div data-aos="fade-up" class="container">
        <h2>Why CSGO items can be good investment</h2>
        <hr>
        <p>
          First of all, let's understand the reason why CSGO items can be a profitable investment. So, because a lot of
          CSGO items are limited in number and after using once, has been permanetly deleted (stickers, cases, graffities
          etc). Because of this, their quantity
          is constantly decreasing, and therefore, the price is growing every day. Skins can also be a good investment if
          the skin is from one of rare collections like
          Cobblestone, Cache, Gods and Monsters, The North etc. or old cases like Bravo, Weapon Cases, Esport Cases etc.
          But usually skins are not the best way to
          invest in CSGO. Only if you like to play with expensive skins, better they would be from old collections or old
          cases. You can check more about CS:GO investments at
          <a href="https://csgoprofit.org/">csgoprofit</a>.
        </p>

        <InArticleAdsense data-ad-client="ca-pub-8686792985613246" data-ad-slot="3833516888">
        </InArticleAdsense>

        <h2>CSGO Investment Examples</h2>
        <hr>

        <h3>Pro Teams Major Stickers and Sticker Capsules Investments</h3>
        <hr>
        <p>Take a look on first limited stickers from Major in Katowice 2014. After the start of discounts, everyone can
          buy Autograph Capsules and
          Team Stickers for $0.25 and Holo/Foil Capsules for $1.5. After some time, depending on buyed volume, design and
          popularity prices are starting to rise.
          Stickers of the most popular teams like Astralis, Natus Vincere, Tiloo, Faze Clan and Autographs Capsules
          usually show the best growth. You can also try
          to buy Pro Player Autographs, they also usually show a steady rise in price. And if a player takes a good place
          in the yearly HLTV top or becomes an MVP tournament,
          or ends his career, you can expect big profits.
        </p>


        <div class="inv-img-wrapper">
          <figure>
            <img class="inv-guide-img-first" src="../assets/img/Ibuypower.webp" alt="IBuyPower 2014">
            <figcaption>Sticker | iBUYPOWER | Katowice 2014 on Steam Market</figcaption>
          </figure>
          <div class="inv-img-wrapper2">
            <figure>
              <img class="inv-guide-img" src="../assets/img/IbuyPowerThen.webp" alt="IBuyPower 2014 Steam">
              <figcaption>Price In 2014</figcaption>
            </figure>
            <figure>
              <img class="inv-guide-img" src="../assets/img/IbuypowerNow.webp" alt="IBuyPower 2014 Steam Now">
              <figcaption>Price Now</figcaption>
            </figure>
          </div>
        </div>

        <hr>
        <h3>Old Weapon Cases Investments</h3>
        <hr>
        <p>
          Let's look on first limited CSGO Weapon Case. You could easily get these after you Match Macking game.
          Now, the oldest one costs $30+. As we all know, you can easily get 1 - 3 cases per week, if you play on Official
          CSGO Servers.
          After Valve released a new case, thousands of people began to receive it. And the price is gradually falling to
          a few cents.
          Over time, the case is removed from the active drop pool and transferred to a rare pool, and its place is
          replaced by a new case.
          In this way, the old case becomes limited. And every day its number is falling. And as you know with decreasing
          quantity, the price of the
          item increases. So far, only one case does not drop at all - Shattered Web Case, it could be obtained during the
          operation with the same name.
        </p>

        <h4>CSGO active pool of cases at this moment:</h4>
        <hr>
        <ul>
          <li>Prisma 2 Case</li>
          <li>Prisma Case</li>
          <li>Danger Zone Case (Prime only)</li>
          <li>Gamma 2 Case</li>
          <li>Gamma Case</li>
          <li>Chroma 3 Case</li>
        </ul>

        <h4>CSGO rare case pool at this moment:</h4>
        <hr>
        <ul>
          <li>Spectrum Case</li>
          <li>Operation Hydra Case</li>
          <li>Operation Wildfire Case</li>
          <li>Falchion Case</li>
          <li>Shadow Case</li>
          <li>Chroma 3 Case</li>
          <li>Chroma 2 Case</li>
          <li>Chroma Case</li>
          <li>Operation Vanguard Weapon Case</li>
          <li>eSports 2014 Summer Case</li>
          <li>Operation Breakout Weapon Case</li>
          <li>Huntsman Weapon Case</li>
          <li>Operation Phoenix Weapon Case</li>
          <li>CS:GO Weapon Case 3</li>
          <li>Winter Offensive Weapon Case</li>
          <li>eSports 2013 Winter Case</li>
          <li>CS:GO Weapon Case 2</li>
          <li>Operation Bravo Case</li>
          <li>eSports 2013 Case</li>
          <li>CS:GO Weapon Case</li>
        </ul>

        <h4>CSGO fully limited pool of cases:</h4>
        <hr>
        <ul>
          <li>Shattered Web Case</li>
        </ul>

        <div class="inv-img-wrapper">
          <figure>
            <img class="inv-guide-img-first" src="../assets/img/Bravo.webp" alt="Bravo">
            <figcaption>Operation Bravo Case on Steam Market</figcaption>
          </figure>
          <div class="inv-img-wrapper2">
            <figure>
              <img class="inv-guide-img" src="../assets/img/BravoThen.webp" alt="Bravo Steam 2014">
              <figcaption>Price In 2014</figcaption>
            </figure>
            <figure>
              <img class="inv-guide-img" src="../assets/img/BravoNow.webp" alt="Bravo Steam 2020">
              <figcaption>Price Now</figcaption>
            </figure>
          </div>
        </div>

        <hr>
        <h3>Operation Passes Investments</h3>
        <hr>
        <p>
          Operation Passes also usually show good profits. At the end of the operation,
          Valve used to issue a 75% discount on the pass. After buying some passes with good discount, you can expect
          great profits over time. Because the number of these passes is completely limited and their number
          is constantly decreasing.
        </p>

        <div class="inv-img-wrapper">
          <figure>
            <img class="inv-guide-img-first" src="../assets/img/Operation.webp" alt="Vanguard Access Pass">
            <figcaption>Operation Vanguard Access Pass on Steam Market</figcaption>
          </figure>
          <div class="inv-img-wrapper2">
            <figure>
              <img class="inv-guide-img" src="../assets/img/OperationThen.webp" alt="Vanguard Access Pass Steam 2015">
              <figcaption>Price In 2015</figcaption>
            </figure>
            <figure>
              <img class="inv-guide-img" src="../assets/img/OperationNow.webp" alt="Vanguard Access Pass Steam 2020">
              <figcaption>Price Now</figcaption>
            </figure>
          </div>
        </div>

        <p>And so on. <br><br></p>

        <InArticleAdsense data-ad-client="ca-pub-8686792985613246" data-ad-slot="8538499912">
        </InArticleAdsense>

        <h2>How to Buy CSGO Investments cheaper and Sell more expensive</h2>
        <hr>
        <p>
          If you want to increase your profit on investment, you should follow these rules for buying and selling
          investments in CSGO. The basic rule is to buy when everyone
          is selling. You can catch such a moment during one of the sales in the steam or after some time after a rapid
          boost in the price, when prices rolled back. It is very
          important to put your items on the Steam Market with the price at which you plan to sell them, immediately after
          buying the investment. In this way you can easily catch
          price boost and lock your profits.
        </p>

        <h2>Best CSGO investments in 2020</h2>
        <hr>
        <p>
          Let's even take an example of Natus Vincere Stickers from the last major in Berlin in 2019. We know, that we can
          buy them for
          $0.25 in unlimited quantities, now we could ell them for $ 1.35, which is 540% profit. <br><br>

          OK. Let's now think what interesting investment we also have in CSGO: <br>
          • Stickers, agents, skins, graffities, pass, cases from Shattered Web Operation. Examples: Sticker | Shattered
          Web,
          Shattered Web Case, Operation Shattered Web Premium Pass, Special Agent Ava | FBI, AWP | The Prince... <br>
          • Stickers, capsules, autographs from CSGO Majors: Sticker | FaZe Clan (Foil) | Atlanta 2017, Sticker | Vox
          Eminor
          (Holo) | Katowice 2015, Sticker | FURIA (Holo) | Katowice 2019... <br>
          • Old sticker capsules: Sticker Capsule 2, Community Sticker Capsule 1... <br>
          • Skins from old collections: AWP | Dragon Lore, AWP | Medusa, Desert Eagle | Blaze... <br>
          • Souvenir Packages in C major: London 2018 Nuke Souvenir Package, DreamHack 2014 Nuke Souvenir Package...
          <br><br>
          And others... <br><br>
        </p>

        <h2>How to find best Steam Investments</h2>
        <hr>
        <p>
          Insider information will help you to increase your profit from Steam Investments. I will often post such
          information in the
          <a href="/news">Blog</a> tab.
          You should also follow the updates in CSGO, professional players and news in the e-sports arena.
        </p>
      </div>

    </div>

    <FooterComponent class="footer" />

  </div>
</template>

<script>
import FooterComponent from '../components/FooterComponent.vue'

export default {
  name: 'InvestmentGuide',
  components: {
    FooterComponent
  }
}
</script>

<style scoped>
h1 {
  text-align: center;
  margin-top: 3vh;
  margin-bottom: 9vh;
}

h2 {
  margin-top: 7vh;
  text-align: start;
  font-size: 26px;
}

h3 {
  margin-top: 2vh;
  text-align: left;
  font-size: 22px;
}

h4 {
  margin-top: 2vh;
  text-align: left;
  font-size: 20px;
}

p {
  text-align: justify;
  font-size: 18px;
}

hr {
  border: 0;
  height: 1px;
  background: #333;
  width: inherit;
  margin-top: 0.5em;
  margin-bottom: 1em;
}

a {
  color: white;
}

li {
  margin-left: 2vw;
  text-align: start;
}

figure {
  margin: 15px 0;
}

figcaption {
  color: #ffffff;
}

.white {
  color: #ffffff;
}

.page-wrapper {
  margin: 0 auto;
  margin-bottom: 10vh;
  width: 60vw;
}

.inv-img-wrapper {
  margin: 0 auto;
  display: grid;
}

.inv-img-wrapper2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.inv-guide-img-first {
  width: 35vw;
}

.inv-guide-img {
  width: 28vw;
}

@media only screen and (max-width : 1200px) {
  .page-wrapper {
    width: 80vw;
  }

  .inv-guide-img-first {
    width: 50vw;
  }

  .inv-guide-img {
    width: 35vw;
  }
}

@media only screen and (max-width : 1000px) {
  .page-wrapper {
    padding-bottom: 10vh;
  }

  .footer {
    display: none;
  }
}</style>

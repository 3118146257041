<template>
    <div class="footer">
        <div id="footer-row">
            <div id="footer-row-first">
                © 2019 <a href="/#/">TradeUpAcademy.org</a><br>
                All rights reserved
            </div>
            <div id="footer-row-first">
                Powered by Steam. Valve and Steam are registered trademarks of Valve Corporation. <br> Tradeupacademy.org is
                not affiliated with Valve in any way.
            </div>
            <div></div>
            <!-- <div id="footer-row-second">
                <a target="_blank" href="https://vk.com/grassknuckles">
                    <svg viewBox="0 0 30 30">
                        <path clip-rule="evenodd"
                            d="M16 24c1 0 1-1.44 1-2 0-1 1-2 2-2s2.715 1.715 4 3c1 1 1 1 2 1h3s2-.076 2-2c0-.625-.685-1.685-3-4-2-2-3.026-.967 0-5 1.843-2.456 3.184-4.68 2.954-5.323-.22-.613-5.346-1.59-5.954-.677-2 3-2.367 3.735-3 5-1 2-1.1 3-2 3-.91 0-1-1.94-1-3 0-3.306.48-5.644-1-6h-3c-1.61 0-3 1-3 1s-1.24.968-1 1c.298.04 2-.414 2 1v2s.01 4-1 4c-1 0-3-4-5-7-.785-1.177-1-1-2-1-1.072 0-2 .042-3 .042S-.128 7.68 0 8c2 5 3.437 8.14 7.237 12.096C10.722 23.726 13.05 23.918 15 24c.5.02 0 0 1 0z"
                            fill-rule="evenodd"></path>
                    </svg>
                </a>
                <a id="facebook" target="_blank" href="https://www.facebook.com/GrassKnuckless">
                    <svg viewBox="0 0 56.693 56.693">
                        <path
                            d="M40.43 21.74h-7.645v-5.015c0-1.883 1.248-2.322 2.127-2.322h5.395V6.125l-7.43-.03c-8.248 0-10.125 6.175-10.125 10.126v5.52h-4.77v8.53h4.77v24.136h10.033V30.27h6.77l.875-8.53z">
                        </path>
                    </svg>
                </a>
                <a target="_blank" href="https://twitter.com/asasin0981231">
                    <svg viewBox="0 0 56.693 56.693">
                        <path
                            d="M52.837 15.065a20.11 20.11 0 0 1-5.805 1.59 10.125 10.125 0 0 0 4.444-5.59 20.232 20.232 0 0 1-6.418 2.453 10.093 10.093 0 0 0-7.377-3.192c-5.58 0-10.105 4.525-10.105 10.107 0 .79.09 1.562.262 2.303-8.4-.422-15.848-4.445-20.833-10.56a10.055 10.055 0 0 0-1.368 5.082 10.1 10.1 0 0 0 4.496 8.412 10.078 10.078 0 0 1-4.578-1.265v.128c0 4.896 3.483 8.98 8.107 9.91a10.162 10.162 0 0 1-4.564.172c1.287 4.015 5.02 6.938 9.44 7.02a20.276 20.276 0 0 1-12.55 4.326c-.816 0-1.62-.047-2.412-.14a28.6 28.6 0 0 0 15.493 4.54c18.59 0 28.756-15.4 28.756-28.756 0-.438-.01-.875-.028-1.31a20.47 20.47 0 0 0 5.042-5.23z">
                        </path>
                    </svg>
                </a>
                <a target="_blank" href="tg://resolve?domain=GrassKnuckles">
                    <svg class="telegram" viewBox="0 0 26 26">
                        <path
                            d="M18.384,22.779c0.322,0.228 0.737,0.285 1.107,0.145c0.37,-0.141 0.642,-0.457 0.724,-0.84c0.869,-4.084 2.977,-14.421 3.768,-18.136c0.06,-0.28 -0.04,-0.571 -0.26,-0.758c-0.22,-0.187 -0.525,-0.241 -0.797,-0.14c-4.193,1.552 -17.106,6.397 -22.384,8.35c-0.335,0.124 -0.553,0.446 -0.542,0.799c0.012,0.354 0.25,0.661 0.593,0.764c2.367,0.708 5.474,1.693 5.474,1.693c0,0 1.452,4.385 2.209,6.615c0.095,0.28 0.314,0.5 0.603,0.576c0.288,0.075 0.596,-0.004 0.811,-0.207c1.216,-1.148 3.096,-2.923 3.096,-2.923c0,0 3.572,2.619 5.598,4.062Zm-11.01,-8.677l1.679,5.538l0.373,-3.507c0,0 6.487,-5.851 10.185,-9.186c0.108,-0.098 0.123,-0.262 0.033,-0.377c-0.089,-0.115 -0.253,-0.142 -0.376,-0.064c-4.286,2.737 -11.894,7.596 -11.894,7.596Z" />
                    </svg>
                </a>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterComponent'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
    margin: 0;
    padding: 0;
}

.footer {
    display: grid;
    align-items: center;
    height: 50px;
    background-color: #25282a;
    font-size: 0.8em;
    line-height: 1.1em;
}

#footer-row {
    padding: 0 1.5em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#footer-row-first {
    text-align: center;
}

a {
    text-decoration: none;
    color: #b574a0;
}

svg {
    padding-right: 1em;
    width: 30px;
    fill: white;
    transition: fill 0.5s ease;
}

svg:hover {
    fill: #d774a0;
}

@media only screen and (max-width : 450px) {
    .footer {
        font-size: 0.6em;
        line-height: 1.5em;
    }
}</style>
